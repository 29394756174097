export const affiliateapp = () => {
  window.open("https://affiliate.app/", "_blank");
};
export const moneymarkets = () => {
  window.open(
    "https://hyfiwebsite.mycryptobrand.com/gxpulse/moneymarkets",
    "_blank"
  );
};
export const bonds = () => {
  window.open("https://hyfiwebsite.mycryptobrand.com/gxpulse/bonds", "_blank");
};
export const retiredapp = () => {
  window.open("https://coverfi.app/", "_blank");
};

export function hexToRgba(hex, opacity) {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  // Parse r, g, b values
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  // Return the rgba string
  return `rgba(${r},${g},${b},${opacity})`;
}
