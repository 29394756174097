import React from "react";
import classNames from "./pricing.module.scss";
import { FaCheckCircle } from "react-icons/fa";
import { allPricings } from "../../assets/data";

const Pricing = ({ refValue, idd }) => {
  //const
  const allFeatures = [
    "All marketing modules",
    "All sales modules",
    "All benefits modules",
    "All wellness modules",
    "All Internal management modules",
    "Mediverse lead marketplace",
    "Instant support",
    "Complimentary mobile app",
  ];

  return (
    <section className={classNames.pricing} ref={refValue} id={idd}>
      <div className={classNames.priceContainer}>
        <div className={classNames.title}>
          The more you win, the more we win
        </div>
        <div className={classNames.desc}>
          There are no hidden fees, no upfront cost and you only pay for what
          you use.
        </div>
        <div className={classNames.allPricing}>
          {allPricings?.map((eachPricing, index) => {
            return (
              <div
                className={classNames.eachPricing}
                key={eachPricing?.planName}
              >
                <div className={classNames.header}>{eachPricing?.planName}</div>
                <div className={classNames.mainContainer}>
                  <div className={classNames.memCount}>
                    <div>{eachPricing?.memCount}</div>
                    <div>Members</div>
                  </div>
                  <div className={classNames.price}>{eachPricing?.price}</div>
                  <div className={classNames.allFeatures}>
                    {allFeatures?.map((eachFeature, index) => {
                      return (
                        <div
                          key={eachFeature + index + eachPricing?.planName}
                          className={`${classNames.eachFeature} ${
                            eachPricing?.accessible - 1 < index &&
                            classNames.notAccesable
                          }`}
                        >
                          <FaCheckCircle />
                          <div>{eachFeature}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Pricing;
