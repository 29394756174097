import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import "./AdvisorSidebar.scss";
import homeIcon from "../../assets/Images/AdvisorList/homeIcon.png";
import personIcon from "../../assets/Images/AdvisorList/personIcon.png";
import goalIcon from "../../assets/Images/AdvisorList/goalIcon.png";
import investmentIcon from "../../assets/Images/AdvisorList/investmentIcon.png";
import settingIcon from "../../assets/Images/AdvisorList/settingIcon1.png";
import questionIcon from "../../assets/Images/AdvisorList/questionIcon.png";
import LogoIcon from "../../assets/relogo.svg";

const AdvisorSidebar = () => {
  const history = useHistory();

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        {/* <img src={LogoIcon} alt="CoverFi" className="sidebar-logo" /> */}
      </div>
      <div className="sidebar-menu">
        <ul className="sidebar-menu-top">
          <li className="sidebar-menu-item">
            <NavLink to="/advisor-profile" activeClassName="active">
              <img src={homeIcon} alt="Profile" className="sidebar-icon" />
              Profile
            </NavLink>
          </li>
          <li className="sidebar-menu-item">
            <NavLink to="/advisor-client" activeClassName="active">
              <img src={personIcon} alt="Client" className="sidebar-icon" />
              Clients
            </NavLink>
          </li>
          {/* <li className="sidebar-menu-item">
            <NavLink to="/goals" activeClassName="active">
              <img src={goalIcon} alt="Team" className="sidebar-icon" />
              Team
            </NavLink>
          </li> */}
        </ul>
        <ul className="sidebar-menu-bottom">
          <li className="sidebar-menu-item">
            <NavLink to="/advisor-library/articles" activeClassName="active">
              <img src={settingIcon} alt="Library" className="sidebar-icon" />
              Library
            </NavLink>
          </li>
          {/* <li className="sidebar-menu-item">
            <NavLink to="/help" activeClassName="active">
              <img src={questionIcon} alt="Help" className="sidebar-icon" />
              Help
            </NavLink>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default AdvisorSidebar;
