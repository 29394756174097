import React from "react";
import andriod from "../../../assets/apple.svg";
import ios from "../../../assets/andriod.svg";
import arrow from "../../../assets/arrow.svg";
import classNames from "./footer.module.scss";
import logoWhite from "../../../assets/logo/logowhite.svg";
import appleLogo from "../../../assets/logo/apple.svg";
import playstoreLogo from "../../../assets/logo/playstore.svg";

const Footer = () => {
  return (
    <div className={classNames.footer}>
      <div className={classNames.mainContainer}>
        <div className={classNames.logoDivider}>
          <img src={logoWhite} alt="logoWhite" />
          <div></div>
        </div>
        <div className={classNames.mainContent}>
          <div>
            <div>
              <div>Overview</div>
              <div>Lorem ipsum</div>
              <div>Lorem ipsum</div>
              <div>Lorem ipsum</div>
              <div>Lorem ipsum</div>
            </div>
            <div>
              <div>Overview</div>
              <div>Lorem ipsum</div>
              <div>Lorem ipsum</div>
              <div>Lorem ipsum</div>
              <div>Lorem ipsum</div>
            </div>
            <div>
              <div>Overview</div>
              <div>Lorem ipsum</div>
              <div>Lorem ipsum</div>
              <div>Lorem ipsum</div>
              <div>Lorem ipsum</div>
            </div>
          </div>
          <div>
            <div className={classNames.subscribeContainer}>
              <input type="text" placeholder="Enter your email" />
              <div>Subscribe</div>
            </div>
            <div className={classNames.downloadBtns}>
              <div>
                <img src={appleLogo} alt="appleLogo" />
                <div>
                  <div>Download On</div>
                  <div>App Store</div>
                </div>
              </div>
              <div>
                <img src={playstoreLogo} alt="playstoreLogo" />
                <div>
                  <div>Download On</div>
                  <div>Google Play</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames.legal}>
          <div>
            CoverFi® and the CoverFi Logo are service marks of Mediverse
            Healthcare Technologies LLC. and may not be used without permission.
            All rights reserved. © Mediverse Healthcare Technologies LLC.
          </div>
          <div>
            <div>Terms & conditions</div>
            <div>Privacy Policy</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
