import React, { useEffect, useState, useRef, useContext } from "react";

import { NavLink, useHistory } from "react-router-dom";
import axios from "axios";
import { Globalcontext } from "../../contexts/GlobalContext";
import UnderReviwe from "./UnderReviwe";
import "./Advisor.scss";
import expamd from "../../assets/expamd.svg";
import { FiSearch, FiPlus } from "react-icons/fi";
import AdvisorSidebar from "../../components/AdvisorSidebar/AdvisorSidebar";
import OutsideClickHandler from "react-outside-click-handler";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LoginContext from "../../contexts/LoginContext";
import Header from "../Advisors/AdvisorProfile/Header";
export default function AdvisorPortal() {
  const [datalist, setdatalist] = useState([]);
  const {
    email,
    token,
    tostShowOn,
    globalseacrhadviser,
    setproposal,
    clinetDetail,
    setclinetDetail,
    setgobalseacrhadviser,
    opentoggleclass,
    setopentoggleclass,
  } = useContext(Globalcontext);
  const { setLoginValues } = useContext(LoginContext);
  console.log("email234234", setLoginValues);
  const [expand, setexpand] = useState(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const history = useHistory();
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);

  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const ref10 = useRef(null);
  const ref11 = useRef(null);
  const ref12 = useRef(null);

  const [width1, setWidth1] = useState(0);
  const [width2, setWidth2] = useState(0);
  const [width3, setWidth3] = useState(0);
  const [width4, setWidth4] = useState(0);
  const [width5, setWidth5] = useState(0);
  const [width6, setWidth6] = useState(0);
  const [width7, setWidth7] = useState(0);
  const [width8, setWidth8] = useState(0);
  const [width9, setWidth9] = useState(0);
  const [width10, setWidth10] = useState(0);
  const [width11, setWidth11] = useState(0);
  const [width12, setWidth12] = useState(0);
  const [createForm, setcreateForm] = useState(false);

  console.log("width1", width1);

  useEffect(() => {
    tablevall();
    tableStore();
    subclass();
    tableprodicever();
    tableprodiceverproduct();
  }, [opentoggleclass]);

  const [toggle, settoggle] = useState(false);
  const [propsal, setpropsal] = useState(false);

  const [propsalkey, setpropsalkey] = useState(0);
  const [active, setactive] = useState(0);
  const [actionkey, setactionkey] = useState(0);
  const [planbvalue, setplanbvalue] = useState([]);
  const [action, setaction] = useState(false);
  const [Investmonth, setInvestmonth] = useState("");
  const [fBalance, setfBalance] = useState("");
  const [gOutCome, setgOutCome] = useState("");
  const [totalInvest, settotalInvest] = useState("");
  const [InvestPermonth, setInvestPermonth] = useState("");
  const [roiannual, setroiannual] = useState("");
  const [pContribution, setpContribution] = useState("");
  const [selecttablematch, setselecttablematch] = useState("Mutual Funds");
  const [mtab, setmtab] = useState("Mutual Funds");
  const [itab, setitab] = useState("Insurance");
  const [btab, setbtab] = useState("Bonds");
  const [gtab, setgtab] = useState("Government Schemes");
  const [formkey, setformkey] = useState(0);
  const [plabid, setplabid] = useState("");
  const [iInvestmonth, setiInvestmonth] = useState("");
  const [ifBalance, setifBalance] = useState("");
  const [igOutCome, setigOutCome] = useState("");
  const [itotalInvest, setitotalInvest] = useState("");
  const [iInvestPermonth, setiInvestPermonth] = useState("");
  const [iroiannual, setiroiannual] = useState("");
  const [ipContribution, setipContribution] = useState("");
  const [bInvestmonth, setbInvestmonth] = useState("");
  const [bfBalance, setbfBalance] = useState("");
  const [bgOutCome, setbgOutCome] = useState("");
  const [btotalInvest, setbtotalInvest] = useState("");
  const [bInvestPermonth, setbInvestPermonth] = useState("");
  const [broiannual, setbroiannual] = useState("");
  const [bpContribution, setbpContribution] = useState("");
  const [gInvestmonth, setgInvestmonth] = useState("");
  const [gfBalance, setgfBalance] = useState("");
  const [ggOutCome, setggOutCome] = useState("");
  const [gtotalInvest, setgtotalInvest] = useState("");
  const [gInvestPermonth, setgInvestPermonth] = useState("");
  const [groiannual, setgroiannual] = useState("");
  const [gpContribution, setgpContribution] = useState("");
  const [underRew, setunderRew] = useState(false);
  const [underRewkey, setunderRewkey] = useState(0);
  const [sublassdata, setsublassdata] = useState([]);
  const [list, setlist] = useState("main");
  const [dropdownAssetsClass, setdropdownAssetsClass] = useState({
    name: "Select Asset Class",
    toggle: false,
  });
  const [serchvalue, setserchvalue] = useState("");
  const closeDropdown = () => {
    setdropdownAssetsClass({
      name: dropdownAssetsClass?.name,
      toggle: false,
    });
  };

  const proposalFucntion = (e) => {
    axios
      .get(
        `https://api.retired.app/retired/proposal/get?advisoremail=${
          localStorage.getItem("LoginAccount") || ""
        }&userEmail=${e}`
      )
      .then(({ data }) => {
        if (data.status) {
          setproposal(data?.data);

          //setdatalist(data?.data);
        } else {
          setproposal([]);
        }
      });
  };

  const clientFucntion = (e) => {
    proposalFucntion(e);
    axios
      .get(
        `https://api.retired.app/retired/useradvisor/get?advisoremail=${
          localStorage.getItem("LoginAccount") || ""
        }&email=${e}`
      )
      .then(({ data }) => {
        if (data.status) {
          history.push("/advisor-client/client-chat");
          setclinetDetail(data?.data?.[0]);

          //setdatalist(data?.data);
        } else {
          setclinetDetail({});
        }
      });
  };
  console.log("asd32234", clinetDetail);
  const planb = (e, k) => {
    // alert(k)
    setplanbvalue([]);
    setaction(false);
    setcreateForm(false);
    setpropsal(propsalkey == k && propsal ? false : true);

    axios
      .get(`https://api.retired.app/retired/proposal/get?PlanbID=${e}`)
      .then(({ data }) => {
        if (data.status) {
          setplanbvalue(data?.data);
          console.log("4534534534534534534", data?.data);
          //setdatalist(data?.data);
        } else {
          setplanbvalue([]);
        }
      });
  };
  function toCardinal(num) {
    var ones = num % 10;
    var tens = num % 100;

    if (tens < 11 || tens > 13) {
      switch (ones) {
        case 1:
          return num + "st";
        case 2:
          return num + "nd";
        case 3:
          return num + "rd";
      }
    }

    return num + "th";
  }
  var monthNames = [
    "",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const tablevall = () => {
    // alert()
    axios
      .get(
        `https://api.retired.app/retired/useradvisor/get?advisoremail=shorupandomains@gmail.com`
      )
      .then(({ data }) => {
        if (data.status) {
          console.log("data?.dataddddddddddddd", data?.data);
          setdatalist(data?.data);
        }
      });
  };
  console.log("asc", active);

  const [pageName, setpageName] = useState("Clients");
  const [storePage, setstorePage] = useState([]);

  const subclass = () => {
    axios
      .get(`https://api.retired.app/retired/subasset/get`)
      .then(({ data }) => {
        if (data.status) {
          console.log("data?.dataddddddddddddd", data?.data);
          setsublassdata(data?.data);
        }
      });
  };

  const tableStore = () => {
    // alert()
    axios.get(`https://api.retired.app/retired/asset/get`).then(({ data }) => {
      if (data.status) {
        console.log("data?.dataddddddddddddd", data?.data);
        setstorePage(data?.data);
      }
    });
  };
  const [storeasstesdetail, setstoreasstesdetail] = useState({});
  const [storeasubsstesdetail, setstoreasubsstesdetail] = useState({});
  const assetsfun = (e) => {
    setlist("Asset class list");
    setstoreasstesdetail(e);
    setopentoggleclass(true);
  };
  const assetssubfun = (e) => {
    setlist("Asset Sub class list");
    setstoreasubsstesdetail(e);
    setopentoggleclass(true);
  };

  useEffect(() => {
    setpageName("Clients");
  }, []);

  const [providerlist, setproviderlist] = useState([]);
  const tableprodicever = () => {
    // alert()
    axios
      .get(`https://api.retired.app/retired/investmentprovider/get`)
      .then(({ data }) => {
        if (data.status) {
          console.log("data?.dataddddddddddddd", data?.data);
          setproviderlist(data?.data);
        }
      });
  };
  const [productdata, setproductdata] = useState([]);

  const tableprodiceverproduct = () => {
    // alert()
    axios
      .get(`https://api.retired.app/retired/product/get`)
      .then(({ data }) => {
        if (data.status) {
          console.log("data?.dataddddddddddddd", data?.data);
          setproductdata(data?.data);
        }
      });
  };

  const [storeproductdetail, setstoreproductdetail] = useState({});
  const [investdeatil, setinvestdeatil] = useState({});
  const productselet = (e) => {
    setlist("click product");
    setopentoggleclass(true);
    setstoreproductdetail(e);
  };
  const investseletd = (e) => {
    setlist("click invest");
    setopentoggleclass(true);
    setinvestdeatil(e);
  };

  const calldropdownassectsclass = (e) => {
    subassetsfuc(e?.asset_id);
    setdropdownAssetsClass({
      name: e?.name,
      toggle: false,
    });
  };

  const [storeid, setstoreid] = useState("");
  const calldropdownSubclassFun = (e) => {
    // subassetsfuc(e?.sub_asset_id)
    investmentprodiver(e?.sub_asset_id);
    setstoreid(e?.sub_asset_id);
    setsubassetsName({
      name: e?.name,
      toggle: false,
    });
  };
  const calldropdowninvetment = (e) => {
    // subassetsfuc(e?.sub_asset_id)
    // investmentprodiver(e?.sub_asset_id)
    productfunction(e?.investment_provider_id);
    setinProviderName({
      name: e?.displayName,
      toggle: false,
    });
  };
  const [itemlist, setitemlist] = useState({});
  const calldropdownproduct = (e) => {
    // subassetsfuc(e?.sub_asset_id)
    // investmentprodiver(e?.sub_asset_id)
    setitemlist(e);
    setproductnamelist({
      name: e?.productName,
      toggle: false,
    });
  };

  // calldropdownSubclassFun(item)
  const [subclasslist, setsubclasslist] = useState([]);
  const [subassetsName, setsubassetsName] = useState({
    name: "Select Sub asset class",
    toggle: false,
  });

  const [inprovider, setinprovider] = useState([]);
  const [inProviderName, setinProviderName] = useState({
    name: "Select investment provider",
    toggle: false,
  });
  const investmentprodiver = (e) => {
    axios
      .get(`https://api.retired.app/retired/subasset/get?sub_asset_id=${e}`)
      .then(({ data }) => {
        if (data.status) {
          console.log("data?.dataddddddddddddd", data?.data);
          setinprovider(data?.data?.[0]?.InvestmentProviderDetails);
        } else {
          setinprovider([]);
          setinProviderName({
            name: "Select investment provider",
            toggle: false,
          });
        }
      });
  };
  const [productData, setproductData] = useState("");
  const [productnamelist, setproductnamelist] = useState({
    name: "Select product",
    toggle: false,
  });
  const productfunction = (e) => {
    axios
      .get(
        `https://api.retired.app/retired/product/get?investment_provider_id=${e}&sub_asset_class_id=${storeid}`
      )
      .then(({ data }) => {
        if (data.status) {
          console.log("data?.dataddddddddddddd", data?.data);
          setproductData(data?.data);
        } else {
          setproductData([]);
          setproductnamelist({
            name: "Select product",
            toggle: false,
          });
        }
      });
  };

  const subassetsfuc = (e) => {
    axios
      .get(
        `https://api.retired.app/retired/subasset/get?parent_asset_class_id=${e}`
      )
      .then(({ data }) => {
        if (data.status) {
          console.log("data?.dataddddddddddddd", data?.data);
          setsubclasslist(data?.data);
        } else {
          setsubclasslist([]);
          setsubassetsName({
            name: "Select Sub asset class",
            toggle: false,
          });
          setinProviderName({
            name: "Select investment provider",
            toggle: false,
          });
          setproductnamelist({
            name: "Select product",
            toggle: false,
          });
        }
      });
  };

  const [profileInfo, setprofileInfo] = useState({});
  // let start = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(such?.createdAt)
  // let datestart =
  let finalstart = profileInfo?.date_of_accomplishment?.split(",");
  let resultstart = finalstart?.[0]?.split("/");
  console.log("popkasdnjnj", resultstart);

  const [counter, setcounter] = useState(0);
  const [addSection, setaddSection] = useState([
    {
      // key: 0,
      product_id: "",
      assetsName: {
        name: "Select Asset Class",
        toggle: false,
        icon: "",
      },
      totalcontri: "",
      storeid: "",
      investApi: [],
      subDetaillist: [],
      productApi: [],
      productitemlist: {},
      targetdate: "",
      percentageinput: "",
      contribution_frequency_toggle: false,
      contribution_frequency: "",
      percentContri: "",
      providerName: {
        name: "Select investment provider",
        toggle: false,
        icon: "",
      },
      productName: {
        name: "Select Product",
        toggle: false,
      },
      numberofmonth: "",
      result: "",
      avgreturn: "",
      subassetsName: {
        name: "Select Sub asset class",
        toggle: false,
        icon: "",
      },
    },
  ]);

  // let content = [{
  //     'id': '1',
  //     'score': '10'
  //   }, {
  //     'id': '2',
  //     'score': '20'
  //   }, {
  //     'id': '3',
  //     'score': '35'
  //   }];

  console.log("xv");

  // useEffect(() => {
  //     calcavg()
  // }, [])

  const [totalavg, settotalavg] = useState();
  const [addtotalcontri, setaddtotalcontri] = useState();
  const [percentContritext, setpercentContritext] = useState();

  const reset = () => {
    setcounter(0);
    setaddSection([
      {
        // key: key + 1,
        product_id: "",
        assetsName: {
          name: "Select Asset Class",
          toggle: false,
          icon: "",
        },
        totalcontri: "",
        percentContri: "",
        storeid: "",
        investApi: [],
        productApi: [],
        productitemlist: {},
        contribution_frequency: "",
        contribution_frequency_toggle: false,
        percentageinput: "",
        avgreturn: "",
        productName: {
          name: "Select Product",
          toggle: false,
        },
        targetdate: "",
        providerName: {
          name: "Select investment provider",
          toggle: false,
          icon: "",
        },
        subassetsName: {
          name: "Select Sub asset class",
          toggle: false,
          icon: "",
        },
        numberofmonth: "",
        subDetaillist: [],
        result: "",
      },
    ]);
  };

  const appendDiv = () => {
    setcounter(counter + 1);
    setaddSection([
      ...addSection,
      {
        // key: key + 1,
        product_id: "",
        assetsName: {
          name: "Select Asset Class",
          toggle: false,
          icon: "",
        },
        totalcontri: "",
        storeid: "",
        investApi: [],
        productApi: [],
        productitemlist: {},
        percentageinput: "",
        contribution_frequency: "",
        contribution_frequency_toggle: false,
        avgreturn: "",
        percentContri: "",
        productName: {
          name: "Select Product",
          toggle: false,
        },
        targetdate: "",
        providerName: {
          name: "Select investment provider",
          toggle: false,
          icon: "",
        },
        subassetsName: {
          name: "Select Sub asset class",
          toggle: false,
          icon: "",
        },
        numberofmonth: "",
        subDetaillist: [],
        result: "",
      },
    ]);
  };

  const [showkey, setshowkey] = useState(0);

  useEffect(() => {
    calcavg(showkey);
    console.log(showkey, "showkey");
  }, [addtotalcontri]);

  const calcavg = async (e) => {
    // alert()

    // if(addSection?.length>0)
    // {
    //     alert()
    // }

    // array1 = [{
    //     name : "users",
    //     checked : true
    //   }, {
    //     name : "active users",
    //     checked : false
    //   }, {
    //     name : "completions",
    //     checked : false
    //   }]

    const newValues1 = [...addSection];
    let pop1 = newValues1?.map(
      (a, i) =>
        (a.percentContri = (a.totalcontri / Number(addtotalcontri)) * 100)
    );
    console.log(pop1, "ppczxckzxkczxck");

    // let pop = addSection[e]?.totalcontri / Number(addtotalcontri) * 100

    const newValues = [...addSection];
    newValues[e].percentContri = pop1[e];
    console.log("addSection[e]?.totalcontri", addSection[e]?.totalcontri);
    setaddSection([...newValues]);

    // setpercentContritext(pop)

    // await add(e)
  };

  // const add = (e) => {

  //     let sum = addSection?.reduce(function (prev, current) {
  //         return prev + +Number(current.targetdate)
  //     }, 0);
  //     let sum1 = addSection?.reduce(function (prev, current) {
  //         return prev + +Number(current.totalcontri)
  //     }, 0);
  //     let pop = Number(addSection[e]?.totalcontri) / Number(sum1) * 100

  //     const newValues = [...addSection]
  //     newValues[e].percentContri = pop
  //     setaddSection(
  //         [...newValues]
  //     )
  //     // setpercentContritext(pop)
  //     settotalavg(sum)
  //     setaddtotalcontri(sum1)
  // }

  const changeavgretun = async (key, e) => {
    const newValues = [...addSection];
    newValues[key].avgreturn = e;

    await setaddSection([...newValues]);

    await postapiselectedsectiomn(key);
    // this.setState({values: newValues })
    console.log("64564564564564564564", newValues);
  };

  useEffect(() => {
    // postapiselectedsectiomn(counter)
  }, []);
  // console.log("addSection[addSection?.length]?.targetdate,", addSection[key]?.targetdate)

  const postapiselectedsectiomn = (key) => {
    // alert(key)
    calcavg(key);
    setshowkey(key);
    console.log("czxczxcxvnxnvbxvn", key, addSection);
    console.log("62427468242842384", addSection[key]?.numberofmonth?.length);

    if (
      addSection[key]?.targetdate?.toString()?.length > 0 &&
      addSection[key]?.avgreturn.length > 0 &&
      addSection[key]?.numberofmonth?.length > 0 &&
      addSection[key]?.contribution_frequency?.toString()?.length > 0
    ) {
      // alert()

      axios
        .post(
          "https://api.retired.app/retired/contribution/getContributionData",
          {
            TotalContributionAmount: addSection[key]?.targetdate,
            annualInterestRate: addSection[key]?.avgreturn,
            numOfContributions: addSection[key]?.numberofmonth,
            interestCalculated: addSection[key]?.contribution_frequency,
          }
        )
        .then(({ data }) => {
          if (data.status) {
            const newValues = [...addSection];

            newValues[key].result = data?.monthlyContribution;
            newValues[key].totalcontri =
              Number(addSection[key]?.numberofmonth) *
              Number(data?.monthlyContribution);
            setaddSection([...newValues]);
          }
        });
    }
  };

  const NumberofMonthfunc = async (key, e) => {
    // alert(e)
    const newValues = [...addSection];
    newValues[key].numberofmonth = e;
    // newValues[key].targetdate = profileInfo?.amountToAccomplish * e / 100

    await setaddSection([...newValues]);
    await postapiselectedsectiomn(key);
    // this.setState({values: newValues })
    console.log("64564564564564564564", newValues);
  };

  const inputpercentage = async (key, e) => {
    const newValues = [...addSection];
    newValues[key].percentageinput = e;
    newValues[key].targetdate = (profileInfo?.amountToAccomplish * e) / 100;

    await setaddSection([...newValues]);
    await postapiselectedsectiomn(key);
    // this.setState({values: newValues })
    console.log("64564564564564564564", newValues);
  };
  const providerdropdown = (key, e) => {
    const newValues = [...addSection];
    newValues[key].providerName.toggle = !e?.providerName.toggle;
    setaddSection([...newValues]);
    // this.setState({values: newValues })
    console.log("czxnczxmnczbmxc123", newValues);
  };

  const productdropdown = (key, e) => {
    const newValues = [...addSection];
    newValues[key].productName.toggle = !e?.productName.toggle;
    setaddSection([...newValues]);
    // this.setState({values: newValues })
    console.log("czxnczxmnczbmxc123", newValues);
  };

  const dropdowncontributionfrequencytoggle = (key, e) => {
    const newValues = [...addSection];
    newValues[key].contribution_frequency_toggle =
      !e?.contribution_frequency_toggle;
    setaddSection([...newValues]);
    // this.setState({values: newValues })
    console.log("czxnczxmnczbmxc123", newValues);
  };

  const assetsdropdown = (key, e) => {
    const newValues = [...addSection];
    newValues[key].assetsName.toggle = !e?.assetsName.toggle;
    setaddSection([...newValues]);
    // this.setState({values: newValues })
    console.log("czxnczxmnczbmxc123", newValues);
  };

  const subassetsdropdown = (key, e) => {
    const newValues = [...addSection];
    newValues[key].subassetsName.toggle = !e?.subassetsName.toggle;
    setaddSection([...newValues]);
    // this.setState({values: newValues })
    console.log("czxnczxmnczbmxc123", newValues);
  };

  // const calldropdownSubclassFun = (e) => {

  //     investmentprodiver(e?.sub_asset_id)
  //     setstoreid(e?.sub_asset_id)
  //     setsubassetsName({
  //         name: e?.name,
  //         toggle: false
  //     })
  // }

  // const calldropdowninvetment = (e) => {

  //
  //     setinProviderName({
  //         name: e?.displayName,
  //         toggle: false
  //     })
  // }

  const PorviderClassSelect = async (key, e) => {
    const newValues = [...addSection];

    newValues[key].providerName.name = e?.displayName;
    newValues[key].providerName.toggle = false;
    newValues[key].providerName.icon = e?.icon;

    await setaddSection([...newValues]);
    await productfunctioncall(key, e?.investment_provider_id);
    await postapiselectedsectiomn(key);
  };

  const ProductClassSelect = async (key, e) => {
    console.log("34534534", e);
    const newValues = [...addSection];

    newValues[key].productName.name = e?.productName;
    newValues[key].productName.toggle = false;
    newValues[key].product_id = e?.product_id;
    newValues[key].productitemlist = e;
    newValues[key].avgreturn = e?.avg_rate_of_return;
    newValues[key].contribution_frequency = e?.contribution_frequency;

    await setaddSection([...newValues]);
    await postapiselectedsectiomn(key);
  };

  const contributionclassselecte = async (key, e) => {
    const newValues = [...addSection];

    newValues[key].contribution_frequency = e;
    newValues[key].contribution_frequency_toggle = false;

    await setaddSection([...newValues]);
    await postapiselectedsectiomn(key);
  };

  const productfunctioncall = (key, e) => {
    console.log("pmcnmzxcn", e, addSection[key]?.storeid);
    axios
      .get(
        `https://api.retired.app/retired/product/get?investment_provider_id=${e}&sub_asset_class_id=${addSection[key]?.storeid}`
      )
      .then(({ data }) => {
        if (data.status) {
          console.log("data?.dataddddddddddddd", data?.data);
          setproductData(data?.data);
          const newValues = [...addSection];
          newValues[key].productApi = data?.data;
          // setsubclasslist(data?.data);
          setaddSection([...newValues]);
        } else {
          setproductData([]);
          setproductnamelist({
            name: "Select product",
            toggle: false,
          });
        }
      });
  };

  const subassetsClassSelect = async (key, e) => {
    // alert()
    // setstoreid(e?.sub_asset_id)
    const newValues = [...addSection];
    newValues[key].subassetsName.icon = e?.icon;
    newValues[key].subassetsName.name = e?.name;
    newValues[key].subassetsName.toggle = false;
    newValues[key].storeid = e?.sub_asset_id;

    await setaddSection([...newValues]);
    await investmentprodiverapi(key, e?.sub_asset_id);
    await postapiselectedsectiomn(key);

    // setsubassetsName({
    //     name: e?.name,
    //     toggle: false
    // })
  };

  const investmentprodiverapi = (key, e) => {
    axios
      .get(`https://api.retired.app/retired/subasset/get?sub_asset_id=${e}`)
      .then(({ data }) => {
        if (data.status) {
          console.log("data?.dataddddddddddddd", data?.data);

          const newValues = [...addSection];
          newValues[key].investApi = data?.data?.[0]?.InvestmentProviderDetails;
          // setsubclasslist(data?.data);
          setaddSection([...newValues]);
          // setinprovider();
        } else {
          // setinprovider([]);
          // setinProviderName({
          //     name: "Select investment provider",
          //     toggle: false
          // })
        }
      });
  };

  const assetsClassSelect = async (key, e) => {
    const newValues = [...addSection];
    newValues[key].assetsName.icon = e?.icon;
    newValues[key].assetsName.name = e?.name;
    newValues[key].assetsName.toggle = false;
    await setaddSection([...newValues]);
    await assetsfuc(key, e?.asset_id);
    await postapiselectedsectiomn(key);
    // this.setState({values: newValues })
    console.log("czxnczxmnczbmxc123", newValues);
  };

  const assetsfuc = (key, e) => {
    axios
      .get(
        `https://api.retired.app/retired/subasset/get?parent_asset_class_id=${e}`
      )
      .then(({ data }) => {
        if (data.status) {
          const newValues = [...addSection];
          newValues[key].subDetaillist = data?.data;
          setaddSection([...newValues]);
        } else {
          const newValues = [...addSection];
          // newValues[key].assetsName.icon = ""
          // newValues[key].assetsName.name = "Select Asset Class"
          // newValues[key].assetsName.toggle = false
          newValues[key].subassetsName.icon = "";
          newValues[key].subassetsName.name = "Select Sub asset class";
          newValues[key].subassetsName.toggle = false;

          newValues[key].productName.name = "Select Product";
          newValues[key].productName.toggle = false;

          newValues[key].providerName.icon = "";
          newValues[key].providerName.name = "Select investment provider";
          newValues[key].providerName.toggle = false;
          newValues[key].totalcontri = "";
          newValues[key].avgreturn = "";
          newValues[key].percentContri = "";
          newValues[key].contribution_frequency = "";

          newValues[key].subDetaillist = [];
          newValues[key].investApi = [];
          newValues[key].productApi = [];
          newValues[key].productitemlist = {};

          setaddSection([...newValues]);
        }
      });
  };

  const closeassets = (key) => {
    // alert(key)
    const newValues = [...addSection];
    newValues[key].assetsName.toggle = false;
    setaddSection([...newValues]);
    // this.setState({values: newValues })
    console.log("czxnczxmnczbmxc123", newValues);
  };

  const closesubassets = (key) => {
    const newValues = [...addSection];
    newValues[key].subassetsName.toggle = false;
    setaddSection([...newValues]);
  };

  const closeprovide = (key) => {
    const newValues = [...addSection];
    newValues[key].providerName.toggle = false;
    setaddSection([...newValues]);
  };
  const closeproduct = (key) => {
    const newValues = [...addSection];
    newValues[key].productName.toggle = false;
    setaddSection([...newValues]);
  };

  const closecontributionfrequencytoggle = (key) => {
    const newValues = [...addSection];
    newValues[key].contribution_frequency_toggle = false;
    setaddSection([...newValues]);
  };

  useEffect(() => {
    setWidth1(ref1?.current?.offsetWidth);
    setWidth2(ref2?.current?.offsetWidth);
    setWidth3(ref3?.current?.offsetWidth);
    setWidth4(ref4?.current?.offsetWidth);
    setWidth5(ref5?.current?.offsetWidth);
    setWidth6(ref6?.current?.offsetWidth);
    setWidth7(ref7?.current?.offsetWidth);
    setWidth8(ref8?.current?.offsetWidth);
    setWidth9(ref9?.current?.offsetWidth);
    setWidth10(ref10?.current?.offsetWidth);
    setWidth11(ref11?.current?.offsetWidth);
    setWidth12(ref12?.current?.offsetWidth);

    const getwidth = () => {
      setWidth1(ref1?.current?.offsetWidth);
      setWidth2(ref2?.current?.offsetWidth);
      setWidth3(ref3?.current?.offsetWidth);
      setWidth4(ref4?.current?.offsetWidth);
      setWidth5(ref5?.current?.offsetWidth);
      setWidth6(ref6?.current?.offsetWidth);
      setWidth7(ref7?.current?.offsetWidth);
      setWidth8(ref8?.current?.offsetWidth);
      setWidth9(ref9?.current?.offsetWidth);
      setWidth7(ref10?.current?.offsetWidth);
      setWidth8(ref11?.current?.offsetWidth);
      setWidth9(ref12?.current?.offsetWidth);
    };

    window.addEventListener("resize", getwidth);

    return () => window.removeEventListener("resize", getwidth);
  }, [createForm]);

  useEffect(() => {
    let sum1 = addSection?.reduce(function (prev, current) {
      return prev + +Number(current.totalcontri);
    }, 0);
    let sum = addSection?.reduce(function (prev, current) {
      return prev + +Number(current.targetdate);
    }, 0);
    setaddtotalcontri(sum1);
    settotalavg(sum);
    // let pop = Number(addSection[showkey]?.totalcontri) / Number(sum1) * 100

    // const newValues = [...addSection]
    // newValues[showkey].percentContri = pop
    // setaddSection(
    //     [...newValues]
    // )
  }, [addSection]);

  console.log("czxnczxmnczbmxc", addSection);
  const subdata = () => {
    const mappedUsers = addSection?.map((item) => ({
      product_id: item?.product_id,
      fields: [
        {
          allocationpercentage: item?.percentContri?.toString(),
        },
        {
          contributionpercentage: item?.percentageinput?.toString(),
        },
        {
          totalinvestmentamount: item?.totalcontri?.toString(),
        },
        {
          expectedROI: item?.avgreturn?.toString(),
        },
        {
          termlength: item?.numberofmonth?.toString(),
        },
        {
          expectedbalance: item?.targetdate?.toString(),
        },
        {
          monthlycontribution: item?.result?.toString(),
        },
        {
          contributionFrequency: item?.contribution_frequency?.toLowerCase(),
        },
      ],
    }));

    let po = {
      advisorEmail: localStorage.getItem("LoginAccount") || "",
      PlanbID: profileInfo?.retirement_plan_id,
      productDetails: [...mappedUsers],
    };
    axios
      .post("https://api.retired.app/retired/proposal/add", {
        advisorEmail: localStorage.getItem("LoginAccount") || "",
        PlanbID: profileInfo?.retirement_plan_id,
        productDetails: [...mappedUsers],
      })
      .then(({ data }) => {
        if (data.status) {
          toast("SuccessFully Submited Proposal");
          // setnamelist("")
          // setdes("")
          // setuploadedImageTracker("")
          // setopentoggleclass(false)

          setcounter(0);
          setaddSection([
            {
              // key: key + 1,
              product_id: "",
              assetsName: {
                name: "Select Asset Class",
                toggle: false,
                icon: "",
              },
              totalcontri: "",
              storeid: "",
              investApi: [],
              productApi: [],
              productitemlist: {},
              percentageinput: "",
              contribution_frequency: "",
              contribution_frequency_toggle: false,
              avgreturn: "",
              percentContri: "",
              productName: {
                name: "Select Product",
                toggle: false,
              },
              targetdate: "",
              providerName: {
                name: "Select investment provider",
                toggle: false,
                icon: "",
              },
              subassetsName: {
                name: "Select Sub asset class",
                toggle: false,
                icon: "",
              },
              numberofmonth: "",
              subDetaillist: [],
              result: "",
            },
          ]);
        }
      });

    console.log("mappedUsers", po);
  };

  const funcdat = () => {
    switch (pageName) {
      case "Clients":
        return (
          <>
            <div className="vaultsMainAdvisor">
              <div className="bodyContainer">
                <div className="trackerDetailsWithdrawadvsor">
                  {datalist?.map((each, j) => {
                    // [1, 2, 2, 2, 2, 2, 2, 2, 2]?.map((each, j) => {
                    return (
                      // each?.ProfileCount?.map((item, i) => {
                      each?.ProfileCount?.filter((temp) =>
                        temp?.firstName
                          ?.toLowerCase()
                          ?.includes(serchvalue?.toLowerCase())
                      )?.map((item, i) => {
                        // [1, 2, 2, 2, 2, 2, 2, 2, 2]?.map((item, i) => {
                        return (
                          <>
                            <>
                              {each?.PlanCount?.map((item, k) => {
                                // [1, 2, 2, 2, 2, 2, 2, 2, 2]?.map((item, k) => {
                                return (
                                  <div
                                    style={{
                                      border: expand
                                        ? "0px solid #E5E5E5"
                                        : "1px solid #E5E5E5",
                                      margin: "15px 3rem",
                                      borderRadius: " 15px",
                                      justifyContent: "space-between",
                                      display: "block",
                                      padding: expand && "0rem",
                                    }}
                                    onClick={() => {
                                      setprofileInfo(item);
                                      setactionkey(k);
                                      setpropsalkey(k);
                                      setformkey(k);
                                    }}
                                  >
                                    <div
                                      style={{
                                        border: expand
                                          ? "0px solid #E5E5E5"
                                          : "1px solid #E5E5E5",
                                        margin: "15px 0rem",
                                        borderRadius: " 15px",
                                        // display: expand ? "none" : "block",
                                        display: "block",
                                        width: "100%",

                                        padding: expand ? "0rem" : "0 1rem",
                                      }}
                                    >
                                      <>
                                        {planbvalue?.map((such, p) => {
                                          console.log("83573784", such);
                                          let dateeee = new Intl.DateTimeFormat(
                                            "en-US",
                                            {
                                              year: "numeric",
                                              month: "2-digit",
                                              day: "2-digit",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              second: "2-digit",
                                            }
                                          ).format(such?.createdAt);
                                          let date = dateeee.split(" ");
                                          let final = date?.[0]?.split(",");
                                          let result = final?.[0]?.split("/");

                                          return (
                                            <>
                                              <div
                                                className="underReiveSectionAdvisor"
                                                onClick={() => {
                                                  setunderRewkey(p);
                                                  // alert(p)
                                                  setunderRew(
                                                    underRewkey == p && underRew
                                                      ? false
                                                      : true
                                                  );
                                                }}
                                              >
                                                <div
                                                  className="titleactionReviw"
                                                  style={{
                                                    fontWeight: "600",
                                                    width: "34rem",
                                                  }}
                                                >
                                                  Prepared by{" "}
                                                  {such?.advisorEmail} on{" "}
                                                  {
                                                    monthNames[
                                                      result[0]?.replace(
                                                        /^0+/,
                                                        ""
                                                      )
                                                    ]
                                                  }{" "}
                                                  {toCardinal(result[1])}{" "}
                                                  {result[2]}
                                                </div>
                                                <div className="titleactionReviw">
                                                  {such?._id}
                                                </div>

                                                <div
                                                  className="underReview"
                                                  style={{
                                                    background:
                                                      such?.proposalStatus ==
                                                      "submitted"
                                                        ? "#FF9100"
                                                        : such?.proposalStatus ==
                                                          "approved"
                                                        ? "#1BA27A"
                                                        : such?.proposalStatus ==
                                                          "rejected"
                                                        ? "#EA0F0F"
                                                        : such?.proposalStatus ==
                                                          "request for resubmission"
                                                        ? "var(--main-color)"
                                                        : "#FF9100",
                                                  }}
                                                >
                                                  {such?.proposalStatus ==
                                                  "submitted"
                                                    ? "Under review"
                                                    : such?.proposalStatus ==
                                                      "approved"
                                                    ? "Approved"
                                                    : such?.proposalStatus ==
                                                      "rejected"
                                                    ? "Rejected"
                                                    : such?.proposalStatus ==
                                                      "request for resubmission"
                                                    ? "Request For Resubmission"
                                                    : ""}
                                                  {/* */}
                                                </div>
                                              </div>

                                              {underRew && underRewkey == p && (
                                                <UnderReviwe such={such} />
                                              )}
                                            </>
                                          );
                                        })}
                                      </>
                                      {/*  */}

                                      <>
                                        <div className="plabbtable-section">
                                          <table
                                            style={{
                                              overflow:
                                                addSection[counter].assetsName
                                                  ?.toggle == true ||
                                                addSection[counter]
                                                  .subassetsName?.toggle ==
                                                  true ||
                                                addSection[counter].productName
                                                  ?.toggle == true ||
                                                addSection[counter].providerName
                                                  ?.toggle == true ||
                                                addSection[counter]
                                                  .contribution_frequency_toggle ==
                                                  true
                                                  ? "visible"
                                                  : "auto",
                                            }}
                                          >
                                            <tr>
                                              <th>
                                                <img
                                                  src={expamd}
                                                  style={{
                                                    marginRight: "25px",
                                                  }}
                                                  onClick={() =>
                                                    setexpand(!expand)
                                                  }
                                                />
                                                Asset class
                                              </th>
                                              <th>Sub asset class</th>
                                              <th>Investment provider</th>
                                              <th>Product</th>

                                              <th>Avg rate of return</th>
                                              <th>Contribution Frequency</th>
                                              <th>
                                                % of total target investment
                                              </th>
                                              <th>
                                                Asset value at target date
                                              </th>
                                              <th>Number Of Months</th>
                                              <th>Monthly Contribution</th>
                                              <th>Total Contribution</th>
                                              <th>% of total contribution</th>
                                            </tr>

                                            {addSection?.map((item, key) => {
                                              return (
                                                <tr
                                                  onClick={() =>
                                                    postapiselectedsectiomn(key)
                                                  }
                                                >
                                                  <td key={key} ref={ref1}>
                                                    <div
                                                      className="dropdown-planb"
                                                      onClick={() =>
                                                        assetsdropdown(
                                                          key,
                                                          item
                                                        )
                                                      }
                                                      // onClick={() => {
                                                      //     setdropdownAssetsClass({
                                                      //         name: dropdownAssetsClass?.name,
                                                      //         toggle: !dropdownAssetsClass?.toggle
                                                      //     })
                                                      // }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        {!item?.assetsName
                                                          ?.icon == "" && (
                                                          <img
                                                            style={{
                                                              width: "10px",
                                                              marginRight:
                                                                "10px",
                                                            }}
                                                            src={
                                                              item?.assetsName
                                                                ?.icon
                                                            }
                                                          />
                                                        )}

                                                        {item?.assetsName?.name}
                                                      </div>
                                                      {item?.assetsName
                                                        ?.toggle ? (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="6"
                                                          height="5"
                                                          viewBox="0 0 6 5"
                                                          fill="none"
                                                        >
                                                          <path
                                                            d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                            fill="#110F0D"
                                                          />
                                                        </svg>
                                                      ) : (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="6"
                                                          height="5"
                                                          viewBox="0 0 6 5"
                                                          fill="none"
                                                        >
                                                          <path
                                                            d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                            fill="#110F0D"
                                                          />
                                                        </svg>
                                                      )}
                                                    </div>

                                                    <OutsideClickHandler
                                                      className="toggledrop"
                                                      onOutsideClick={() => {
                                                        closeassets(key);
                                                      }}
                                                    >
                                                      {item?.assetsName
                                                        ?.toggle && (
                                                        <div className="dropdwonlist">
                                                          {storePage?.map(
                                                            (item) => {
                                                              return (
                                                                <div
                                                                  className="listitemName"
                                                                  onClick={() =>
                                                                    assetsClassSelect(
                                                                      key,
                                                                      item
                                                                    )
                                                                  }
                                                                >
                                                                  <img
                                                                    src={
                                                                      item?.icon
                                                                    }
                                                                  />
                                                                  {item?.name}
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      )}
                                                    </OutsideClickHandler>
                                                  </td>
                                                  {/*  */}

                                                  <td ref={ref2}>
                                                    <div
                                                      className="dropdown-planb"
                                                      // onClick={() => {
                                                      //     subassetsdropdown
                                                      //     setsubassetsName({
                                                      //         name: subassetsName?.name,
                                                      //         toggle: !subassetsName?.toggle
                                                      //     })
                                                      // }}

                                                      onClick={() =>
                                                        subassetsdropdown(
                                                          key,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        {!item?.subassetsName
                                                          ?.icon == "" && (
                                                          <img
                                                            style={{
                                                              width: "10px",
                                                              marginRight:
                                                                "10px",
                                                            }}
                                                            src={
                                                              item
                                                                ?.subassetsName
                                                                ?.icon
                                                            }
                                                          />
                                                        )}

                                                        {
                                                          item?.subassetsName
                                                            ?.name
                                                        }
                                                      </div>

                                                      {/* {item?.subassetsName?.name} */}
                                                      {item?.subassetsName
                                                        ?.toggle ? (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="6"
                                                          height="5"
                                                          viewBox="0 0 6 5"
                                                          fill="none"
                                                        >
                                                          <path
                                                            d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                            fill="#110F0D"
                                                          />
                                                        </svg>
                                                      ) : (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="6"
                                                          height="5"
                                                          viewBox="0 0 6 5"
                                                          fill="none"
                                                        >
                                                          <path
                                                            d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                            fill="#110F0D"
                                                          />
                                                        </svg>
                                                      )}
                                                    </div>

                                                    {item?.subassetsName
                                                      ?.toggle && (
                                                      <OutsideClickHandler
                                                        onOutsideClick={() => {
                                                          closesubassets(key);
                                                        }}
                                                      >
                                                        <div
                                                          className="dropdwonlist"
                                                          // ref={ref}
                                                        >
                                                          {item?.subDetaillist?.map(
                                                            (item) => {
                                                              return (
                                                                <div
                                                                  className="listitemName"
                                                                  onClick={() =>
                                                                    subassetsClassSelect(
                                                                      key,
                                                                      item
                                                                    )
                                                                  }
                                                                  // onClick={() =>

                                                                  //     calldropdownSubclassFun(item)
                                                                  // }
                                                                >
                                                                  <img
                                                                    src={
                                                                      item?.icon
                                                                    }
                                                                  />
                                                                  {item?.name}
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </OutsideClickHandler>
                                                    )}
                                                  </td>

                                                  {/*  */}
                                                  <td ref={ref3}>
                                                    <div
                                                      className="dropdown-planb"
                                                      onClick={() =>
                                                        providerdropdown(
                                                          key,
                                                          item
                                                        )
                                                      }

                                                      // onClick={() => {

                                                      //     setinProviderName({
                                                      //         name: inProviderName?.name,
                                                      //         toggle: !inProviderName?.toggle
                                                      //     })
                                                      // }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        {!item?.providerName
                                                          ?.icon == "" && (
                                                          <img
                                                            style={{
                                                              width: "10px",
                                                              marginRight:
                                                                "10px",
                                                            }}
                                                            src={
                                                              item?.providerName
                                                                ?.icon
                                                            }
                                                          />
                                                        )}

                                                        {
                                                          item?.providerName
                                                            ?.name
                                                        }
                                                      </div>
                                                      {/* {item?.providerName?.name} */}
                                                      {item?.providerName
                                                        ?.toggle ? (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="6"
                                                          height="5"
                                                          viewBox="0 0 6 5"
                                                          fill="none"
                                                        >
                                                          <path
                                                            d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                            fill="#110F0D"
                                                          />
                                                        </svg>
                                                      ) : (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="6"
                                                          height="5"
                                                          viewBox="0 0 6 5"
                                                          fill="none"
                                                        >
                                                          <path
                                                            d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                            fill="#110F0D"
                                                          />
                                                        </svg>
                                                      )}
                                                    </div>

                                                    {item?.providerName
                                                      ?.toggle && (
                                                      <OutsideClickHandler
                                                        onOutsideClick={() => {
                                                          closeprovide(key);
                                                        }}
                                                      >
                                                        <div
                                                          className="dropdwonlist"
                                                          // ref={ref}
                                                        >
                                                          {item?.investApi?.map(
                                                            (item) => {
                                                              return (
                                                                <div
                                                                  className="listitemName"
                                                                  onClick={() =>
                                                                    PorviderClassSelect(
                                                                      key,
                                                                      item
                                                                    )
                                                                  }

                                                                  // onClick={() =>

                                                                  //     calldropdowninvetment(item)
                                                                  // }
                                                                >
                                                                  <img
                                                                    src={
                                                                      item?.icon
                                                                    }
                                                                  />
                                                                  {
                                                                    item?.displayName
                                                                  }
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </OutsideClickHandler>
                                                    )}
                                                  </td>

                                                  <td ref={ref4}>
                                                    <div
                                                      className="dropdown-planb"
                                                      onClick={() =>
                                                        productdropdown(
                                                          key,
                                                          item
                                                        )
                                                      }
                                                      // onClick={() => {
                                                      //     setproductnamelist({
                                                      //         name: productnamelist?.name,
                                                      //         toggle: !productnamelist?.toggle
                                                      //     })
                                                      // }}
                                                    >
                                                      {item?.productName?.name}
                                                      {item?.productName
                                                        ?.toggle ? (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="6"
                                                          height="5"
                                                          viewBox="0 0 6 5"
                                                          fill="none"
                                                        >
                                                          <path
                                                            d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                            fill="#110F0D"
                                                          />
                                                        </svg>
                                                      ) : (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="6"
                                                          height="5"
                                                          viewBox="0 0 6 5"
                                                          fill="none"
                                                        >
                                                          <path
                                                            d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                            fill="#110F0D"
                                                          />
                                                        </svg>
                                                      )}
                                                    </div>

                                                    {item?.productName
                                                      ?.toggle && (
                                                      <OutsideClickHandler
                                                        onOutsideClick={() => {
                                                          closeproduct(key);
                                                        }}
                                                      >
                                                        <div className="dropdwonlist">
                                                          {item?.productApi?.map(
                                                            (item) => {
                                                              return (
                                                                <div
                                                                  className="listitemName"
                                                                  onClick={() =>
                                                                    ProductClassSelect(
                                                                      key,
                                                                      item
                                                                    )
                                                                  }
                                                                >
                                                                  {/* <img src={item?.icon} /> */}
                                                                  {
                                                                    item?.productName
                                                                  }
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </OutsideClickHandler>
                                                    )}
                                                  </td>
                                                  <td ref={ref5}>
                                                    <div className="text-handle">
                                                      <input
                                                        value={item?.avgreturn}
                                                        onChange={(e) =>
                                                          changeavgretun(
                                                            key,
                                                            e.target.value
                                                          )
                                                        }
                                                      />

                                                      {/* {item?.productitemlist?.avg_rate_of_return} */}
                                                    </div>
                                                  </td>
                                                  <td ref={ref6}>
                                                    <div
                                                      className="dropdown-planb"
                                                      onClick={() =>
                                                        dropdowncontributionfrequencytoggle(
                                                          key,
                                                          item
                                                        )
                                                      }
                                                      // onClick={() => {
                                                      //     setproductnamelist({
                                                      //         name: productnamelist?.name,
                                                      //         toggle: !productnamelist?.toggle
                                                      //     })
                                                      // }}
                                                    >
                                                      {
                                                        item?.contribution_frequency
                                                      }
                                                      {item?.contribution_frequency_toggle ? (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="6"
                                                          height="5"
                                                          viewBox="0 0 6 5"
                                                          fill="none"
                                                        >
                                                          <path
                                                            d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z"
                                                            fill="#110F0D"
                                                          />
                                                        </svg>
                                                      ) : (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="6"
                                                          height="5"
                                                          viewBox="0 0 6 5"
                                                          fill="none"
                                                        >
                                                          <path
                                                            d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z"
                                                            fill="#110F0D"
                                                          />
                                                        </svg>
                                                      )}
                                                    </div>

                                                    {item?.contribution_frequency_toggle && (
                                                      <OutsideClickHandler
                                                        onOutsideClick={() => {
                                                          closecontributionfrequencytoggle(
                                                            key
                                                          );
                                                        }}
                                                      >
                                                        <div className="dropdwonlist">
                                                          <div
                                                            className="listitemName"
                                                            onClick={() =>
                                                              contributionclassselecte(
                                                                key,
                                                                "Monthly"
                                                              )
                                                            }
                                                          >
                                                            {/* <img src={item?.icon} /> */}
                                                            Monthly
                                                          </div>
                                                          <div
                                                            className="listitemName"
                                                            onClick={() =>
                                                              contributionclassselecte(
                                                                key,
                                                                "Annually"
                                                              )
                                                            }
                                                          >
                                                            {/* <img src={item?.icon} /> */}
                                                            Annually
                                                          </div>
                                                        </div>
                                                      </OutsideClickHandler>
                                                    )}
                                                  </td>

                                                  <td ref={ref7}>
                                                    <input
                                                      placeholder="0"
                                                      value={
                                                        item?.percentageinput
                                                      }
                                                      onChange={(e) =>
                                                        inputpercentage(
                                                          key,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td ref={ref8}>
                                                    <div className="text-handle">
                                                      {profileInfo?.CurrencyForTheAmount ==
                                                      "USD"
                                                        ? "$"
                                                        : "₹"}
                                                      {item?.targetdate}
                                                    </div>
                                                  </td>
                                                  <td ref={ref9}>
                                                    <input
                                                      placeholder="0"
                                                      value={
                                                        item?.numberofmonth
                                                      }
                                                      onChange={(e) =>
                                                        NumberofMonthfunc(
                                                          key,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td ref={ref10}>
                                                    <div className="text-handle">
                                                      {profileInfo?.CurrencyForTheAmount ==
                                                      "USD"
                                                        ? "$"
                                                        : "₹"}
                                                      {Number(
                                                        item?.result
                                                      )?.toFixed(2)}
                                                    </div>
                                                  </td>

                                                  <td ref={ref11}>
                                                    <div className="text-handle">
                                                      {profileInfo?.CurrencyForTheAmount ==
                                                      "USD"
                                                        ? "$"
                                                        : "₹"}
                                                      {Number(
                                                        item?.totalcontri
                                                      )?.toFixed(2)}
                                                    </div>
                                                  </td>

                                                  <td ref={ref12}>
                                                    <div className="text-handle">
                                                      {Number(
                                                        item?.percentContri
                                                      )?.toFixed(2)}
                                                      %
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            })}

                                            <tr
                                              style={{
                                                border: "0px solid",
                                                marginTop: "1rem",
                                              }}
                                            >
                                              <td
                                                style={{
                                                  width: `${width1}px`,
                                                  borderRight: "0px solid",
                                                }}
                                              ></td>
                                              <td
                                                style={{
                                                  width: `${width2}px`,
                                                  borderRight: "0px solid",
                                                }}
                                              ></td>
                                              <td
                                                style={{
                                                  width: `${width3}px`,
                                                  borderRight: "0px solid",
                                                }}
                                              ></td>
                                              <td
                                                style={{
                                                  width: `${width4}px`,
                                                  borderRight: "0px solid",
                                                }}
                                              ></td>
                                              <td
                                                style={{
                                                  width: `${width5}px`,
                                                  borderRight: "0px solid",
                                                }}
                                              ></td>
                                              <td
                                                style={{
                                                  width: `${width6}px`,
                                                  borderRight: "0px solid",
                                                }}
                                              ></td>

                                              <td
                                                style={{
                                                  width: `${width7}px`,
                                                  borderRight: "0px solid",
                                                }}
                                              ></td>
                                              <td
                                                style={{
                                                  width: `${width8}px`,
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  borderRight: "0px solid",
                                                }}
                                              >
                                                {profileInfo?.CurrencyForTheAmount ==
                                                "USD"
                                                  ? "$"
                                                  : "₹"}{" "}
                                                {totalavg}
                                              </td>

                                              <td
                                                style={{
                                                  width: `${width9}px`,
                                                  borderRight: "0px solid",
                                                }}
                                              ></td>
                                              <td
                                                style={{
                                                  width: `${width10}px`,
                                                  borderRight: "0px solid",
                                                }}
                                              ></td>

                                              <td
                                                style={{
                                                  width: `${width11}px`,
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  border: "1px solid #ddd",
                                                  marginTop: "1rem",
                                                  borderRadius: "62px",
                                                }}
                                              >
                                                {profileInfo?.CurrencyForTheAmount ==
                                                "USD"
                                                  ? "$"
                                                  : "₹"}
                                                {Number(
                                                  addtotalcontri
                                                )?.toFixed(2)}
                                              </td>

                                              <td
                                                style={{
                                                  width: `${width12}px`,
                                                }}
                                              ></td>
                                            </tr>
                                          </table>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "2.5rem",
                                            }}
                                          >
                                            <div
                                              className="addlalbep"
                                              onClick={appendDiv}
                                            >
                                              Add Another Product
                                            </div>
                                            <div
                                              className="addlalbep"
                                              style={{
                                                background: "#1BA27A",
                                              }}
                                              onClick={subdata}
                                            >
                                              Submit Proposal
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          </>
                        );
                      })
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        );

        break;

      default:
        break;
    }
  };

  return (
    <div className="rightsidedatashow">
      <Header />
      <div className="flexContainers">
        <div className="searchProfileContainer">
          {/* <div className="searchBoxContainer">
          <FiSearch className="searchIcon" />
          <input
            type="text"
            placeholder="Search"
            value={serchvalue}
            onChange={(e) => setserchvalue(e.target.value)}
            className="searchInput"
          />
        </div> */}
        </div>

        {funcdat()}

        <ToastContainer />
      </div>
    </div>
  );
}
