import React, { useEffect, useState } from "react";
import MenuList from "../../components/MenuList/MenuList";
import Location from "../../components/Location/Location";
import coverpicc from "../../assets/coverpic.png";
import "./ProfilePage.scss";
import shorupan from "../../assets/shorupan.svg";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
export default function ProfilePage() {
  const { id, pname } = useParams();
  const history = useHistory();
  const [bvalue, setbvalue] = useState({});
  useEffect(() => {
    planb();
    // setglobalseacrhadviser('')
  }, [id]);
  const planb = () => {
    axios
      .get(
        `https://api.retired.app/retired/user/get/userprofiles?userType=advisor&email=${pname}`
      )
      .then(({ data }) => {
        if (data.status) {
          setbvalue(data?.data?.[0]);
          console.log("4534534534534534534", data?.data);
          //setdatalist(data?.data);
        }
      });
  };

  return (
    <div className="menulistProfilepage">
      <MenuList />
      <div
        style={{
          width: "100%",
        }}
      >
        <Location />
        <div className="profilepagesection">
          <div className="left">
            <div className="leftcontainer">
              <div
                className="coverpicsection"
                style={{
                  backgroundImage: `url(${bvalue?.coverPhoto})`,
                }}
              >
                <div className="photosection">
                  <img src={bvalue?.profilePhoto} />
                </div>
              </div>
              <div className="buttonsection">
                <div
                  style={{
                    border: " 1px solid #E5E5E5",
                    color: " #110F0D",
                    background: "#fff",
                  }}
                  onClick={() =>
                    history.push(`/advisors/${id}/${pname}/webiste`)
                  }
                >
                  Website
                </div>
                <div>Follow</div>
              </div>
              <div className="hadletext">
                <div className="text-sec">
                  <h1>
                    {bvalue?.firstName} {bvalue?.lastName}
                  </h1>
                  <h3>
                    {bvalue?.jobTitle} @{bvalue?.brokerAgeName} |{" "}
                    {bvalue?.PANnumber}
                  </h3>
                  <h4>
                    {bvalue?.city}, {bvalue?.countryName}
                  </h4>
                </div>
                <div className="text-section-div">
                  <div className="text-sec">
                    <h1
                      style={{
                        textAlign: "end",
                      }}
                    >
                      {bvalue?.connectedUserCount}
                    </h1>
                    <h3>Clients</h3>
                  </div>
                  <div className="text-sec">
                    <h1
                      style={{
                        textAlign: "end",
                      }}
                    >
                      {bvalue?.totalUserplanCount}
                    </h1>
                    <h3>Plans</h3>
                  </div>
                  <div className="text-sec">
                    <h1
                      style={{
                        textAlign: "end",
                      }}
                    >
                      ₹0.00
                    </h1>
                    <h3
                      style={{
                        textAlign: "end",
                      }}
                    >
                      AUM
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="leftbelowcontain">
              <div className="tabs">
                <div
                  style={{
                    color: "#FFF",
                    padding: "0 3rem",
                    background: "var(--main-color)",
                  }}
                >
                  About
                </div>
                <div>Specializations</div>
                <div>Testimonials</div>
                <div>Content</div>
              </div>

              <div className="text">{bvalue?.about} </div>
            </div>
          </div>

          <div className="rightside">
            <div className="box1">
              <div className="name">Years of experience</div>

              <div
                className="content-sec"
                style={{
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    textDecoration: "none",
                  }}
                >
                  {bvalue?.yearsOfExperience}
                </div>
              </div>
            </div>

            <div className="box2">
              <div className="name">Contact</div>

              <div className="content-sec">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M9.70662 0H9.70176C4.35112 0 0 4.35233 0 9.70419C0 11.827 0.684145 13.7945 1.84743 15.3921L0.63805 18.9972L4.3681 17.8048C5.90257 18.8213 7.73303 19.4084 9.70662 19.4084C15.0573 19.4084 19.4084 15.0548 19.4084 9.70419C19.4084 4.35354 15.0573 0 9.70662 0Z"
                    fill="var(--main-color)"
                  />
                  <path
                    d="M15.3532 13.7035C15.1191 14.3646 14.1899 14.9129 13.4487 15.073C12.9417 15.181 12.2794 15.2671 10.0498 14.3428C7.19801 13.1613 5.36149 10.2634 5.21835 10.0754C5.08128 9.88733 4.06598 8.54088 4.06598 7.14833C4.06598 5.75577 4.77317 5.07769 5.05823 4.78657C5.29235 4.5476 5.6793 4.43843 6.05049 4.43843C6.17057 4.43843 6.27853 4.4445 6.37558 4.44935C6.66064 4.46148 6.80377 4.47846 6.99179 4.92849C7.22591 5.49255 7.79603 6.8851 7.86396 7.02824C7.9331 7.17137 8.00224 7.36546 7.9052 7.55348C7.81422 7.74756 7.73416 7.83368 7.59103 7.99865C7.44789 8.16363 7.31203 8.28978 7.16889 8.46688C7.03789 8.62094 6.8899 8.78591 7.05487 9.07097C7.21984 9.34996 7.78996 10.2804 8.62937 11.0276C9.7126 11.9919 10.5908 12.3 10.905 12.431C11.1391 12.5281 11.4181 12.505 11.5892 12.3231C11.8063 12.089 12.0744 11.7008 12.3473 11.3187C12.5414 11.0446 12.7864 11.0106 13.0436 11.1076C13.3056 11.1986 14.6921 11.884 14.9771 12.0259C15.2622 12.169 15.4502 12.237 15.5193 12.357C15.5873 12.4771 15.5873 13.0412 15.3532 13.7035Z"
                    fill="#FAFAFA"
                  />
                </svg>
                <div>{bvalue?.phoneNumber}</div>
              </div>
              <div className="content-sec">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M0.257019 6.313H4.60965V19.4084H0.257019V6.313Z"
                    fill="var(--main-color)"
                  />
                  <path
                    d="M2.46236 0C0.973482 0 0 0.977665 0 2.26268C0 3.51934 0.944513 4.52489 2.4052 4.52489H2.43355C3.95156 4.52489 4.89622 3.51934 4.89622 2.26268C4.86787 0.977665 3.95156 0 2.46236 0Z"
                    fill="var(--main-color)"
                  />
                  <path
                    d="M15.2936 6.00558C12.9841 6.00558 11.9493 7.27479 11.3708 8.16725V6.31293H7.01807V19.4083H11.3707V12.0953C11.3707 11.7038 11.399 11.3129 11.514 11.033C11.8286 10.2512 12.5448 9.44127 13.7472 9.44127C15.3223 9.44127 15.9523 10.6422 15.9523 12.4026V19.4083H20.3046V11.8996C20.3046 7.87725 18.1572 6.00558 15.2936 6.00558Z"
                    fill="var(--main-color)"
                  />
                </svg>
                <div onClick={() => window.open(`https://${bvalue?.linkedIn}`)}>
                  {bvalue?.linkedIn}
                </div>
              </div>

              <div
                className="content-sec"
                onClick={() => window.open(`https://${bvalue?.facebook}`)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1640_1905)">
                    <path
                      d="M18.3389 -9.19372e-07H1.07054C0.479216 0.000295259 -0.000148055 0.479956 3.43024e-08 1.07142V18.3398C0.000296212 18.9311 0.479956 19.4105 1.07142 19.4103H18.3389C18.9305 19.4105 19.4102 18.931 19.4103 18.3393C19.4103 18.3392 19.4103 18.339 19.4103 18.3389V1.07053C19.41 0.479215 18.9304 -0.000149008 18.3389 -9.19372e-07Z"
                      fill="var(--main-color)"
                    />
                    <path
                      d="M13.4015 19.4103V11.904H15.932L16.3111 8.9659H13.4015V7.09465C13.4015 6.24595 13.6371 5.66766 14.8541 5.66766H16.3964V3.04693C16.1281 3.01124 15.2074 2.93142 14.1361 2.93142C11.8994 2.93142 10.3686 4.29621 10.3686 6.80365V8.9659H7.84753V11.904H10.3686V19.4103H13.4015Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1640_1905">
                      <rect width="19.4103" height="19.4103" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div>{bvalue?.facebook}</div>
              </div>

              <div
                className="content-sec"
                onClick={() => window.open(`https://${bvalue?.instagram}`)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M19.078 3.4718C18.9013 1.81646 17.594 0.508834 15.9385 0.332312C11.787 -0.110771 7.62333 -0.110771 3.47195 0.332312C1.81646 0.508982 0.508982 1.81646 0.332312 3.4718C-0.110771 7.62318 -0.110771 11.787 0.332312 15.9384C0.508982 17.5939 1.81646 18.9013 3.4718 19.078C7.62318 19.5211 11.787 19.5211 15.9385 19.078C17.594 18.9013 18.9013 17.5939 19.078 15.9384C19.5211 11.787 19.5211 7.62333 19.078 3.4718Z"
                    fill="var(--main-color)"
                  />
                  <path
                    d="M18.0223 4.17389C17.8656 2.70499 16.7055 1.54471 15.2364 1.38789C11.5526 0.99486 7.85774 0.99486 4.17388 1.38789C2.70498 1.54471 1.54471 2.70499 1.38788 4.17389C0.994852 7.85775 0.994852 11.5526 1.38788 15.2363C1.54471 16.7053 2.70498 17.8656 4.17388 18.0223C7.85774 18.4155 11.5526 18.4155 15.2363 18.0223C16.7053 17.8656 17.8656 16.7053 18.0223 15.2363C18.4155 11.5526 18.4155 7.85775 18.0223 4.17389Z"
                    fill="var(--main-color)"
                  />
                  <path
                    d="M0.290833 15.536C0.304161 15.6702 0.317933 15.8044 0.332297 15.9385C0.508968 17.5939 1.81645 18.9013 3.47193 19.078C7.62331 19.5211 11.7871 19.5211 15.9385 19.078C17.594 18.9013 18.9013 17.5939 19.078 15.9385C19.0924 15.8044 19.1061 15.6702 19.1196 15.536H0.290833Z"
                    fill="var(--main-color)"
                  />
                  <path
                    d="M19.078 15.9384C19.3161 13.7077 19.426 11.4735 19.4082 9.23957L14.9715 4.80282C14.4172 4.17004 13.6076 3.77509 12.6563 3.74088C10.6999 3.67039 8.73724 3.67394 6.78054 3.74117C5.15541 3.79715 4.01898 4.88946 3.72754 6.49489C3.67615 6.77833 3.64535 7.06548 3.60492 7.35099V12.0576C3.62728 12.2101 3.66016 12.3619 3.67037 12.5153C3.73553 13.4918 4.13686 14.3282 4.77941 14.8941L9.29435 19.4088C11.5101 19.4244 13.7259 19.3142 15.9385 19.078C17.594 18.9013 18.9013 17.5939 19.078 15.9384Z"
                    fill="var(--main-color)"
                  />
                  <path
                    d="M19.078 15.9385C18.9015 17.5937 17.5937 18.9015 15.9385 19.078C13.7262 19.3141 11.5099 19.4244 9.2945 19.4088L4.78638 14.9007C5.30898 15.3582 5.99019 15.6367 6.78069 15.6665C8.73695 15.7399 10.7 15.738 12.6563 15.6675C14.4256 15.6035 15.706 14.294 15.7613 12.5246C15.8202 10.646 15.8202 8.76243 15.7613 6.88377C15.7361 6.0675 15.4495 5.34927 14.9745 4.80579L19.4084 9.23972C19.4259 11.4733 19.316 13.7079 19.078 15.9385Z"
                    fill="var(--main-color)"
                  />
                  <path
                    d="M11.9955 7.60333C11.4272 6.98447 10.6116 6.59618 9.70517 6.59618C7.98823 6.59618 6.59619 7.98822 6.59619 9.70516C6.59619 10.6116 6.98448 11.4271 7.60334 11.9955L14.7965 19.1886C15.1772 19.1555 15.5579 19.1186 15.9384 19.078C17.5939 18.9013 18.9014 17.5939 19.078 15.9384C19.1186 15.5579 19.1555 15.1772 19.1886 14.7965L11.9955 7.60333Z"
                    fill="var(--main-color)"
                  />
                  <path
                    d="M19.3727 11.796L13.5836 6.00708C13.4485 5.82982 13.2357 5.71476 12.9957 5.71476C12.5873 5.71476 12.256 6.04588 12.256 6.45446C12.256 6.69451 12.371 6.90732 12.5483 7.04237L19.2692 13.7634C19.3148 13.1078 19.3493 12.4519 19.3727 11.796Z"
                    fill="var(--main-color)"
                  />
                  <path
                    d="M3.60492 7.35099C3.64535 7.06548 3.67615 6.77833 3.72754 6.49489C4.01883 4.88946 5.15541 3.79715 6.78054 3.74117C8.73724 3.67394 10.6999 3.67039 12.6563 3.74088C14.425 3.80456 15.706 5.11425 15.7613 6.88362C15.82 8.76243 15.82 10.6458 15.7613 12.5246C15.706 14.294 14.4255 15.6035 12.6564 15.6674C10.7 15.738 8.73694 15.7398 6.78069 15.6663C5.01162 15.5998 3.7887 14.2872 3.67037 12.5152C3.66016 12.3619 3.62728 12.2099 3.60492 12.0574C3.60492 10.4886 3.60492 8.9197 3.60492 7.35099ZM9.70678 14.6188C10.6021 14.6188 11.4984 14.6456 12.3925 14.6125C13.7945 14.5606 14.6568 13.7239 14.6895 12.3232C14.7301 10.578 14.7301 8.8304 14.6895 7.08502C14.6568 5.68469 13.7939 4.82844 12.3925 4.79868C10.6138 4.76106 8.8332 4.76136 7.05451 4.79868C5.6638 4.82785 4.79896 5.66677 4.7612 7.0517C4.71322 8.819 4.71322 10.5894 4.7612 12.3567C4.79896 13.7415 5.6638 14.561 7.05465 14.6125C7.93756 14.6454 8.82269 14.6188 9.70678 14.6188Z"
                    fill="var(--main-color)"
                  />
                  <path
                    d="M3.60492 7.35099C3.64535 7.06548 3.67615 6.77833 3.72754 6.49489C4.01883 4.88946 5.15541 3.79715 6.78054 3.74117C8.73724 3.67394 10.6999 3.67039 12.6563 3.74088C14.425 3.80456 15.706 5.11425 15.7613 6.88362C15.82 8.76243 15.82 10.6458 15.7613 12.5246C15.706 14.294 14.4255 15.6035 12.6564 15.6674C10.7 15.738 8.73694 15.7398 6.78069 15.6663C5.01162 15.5998 3.7887 14.2872 3.67037 12.5152C3.66016 12.3619 3.62728 12.2099 3.60492 12.0574C3.60492 10.4886 3.60492 8.9197 3.60492 7.35099ZM9.70678 14.6188C10.6021 14.6188 11.4984 14.6456 12.3925 14.6125C13.7945 14.5606 14.6568 13.7239 14.6895 12.3232C14.7301 10.578 14.7301 8.8304 14.6895 7.08502C14.6568 5.68469 13.7939 4.82844 12.3925 4.79868C10.6138 4.76106 8.8332 4.76136 7.05451 4.79868C5.6638 4.82785 4.79896 5.66677 4.7612 7.0517C4.71322 8.819 4.71322 10.5894 4.7612 12.3567C4.79896 13.7415 5.6638 14.561 7.05465 14.6125C7.93756 14.6454 8.82269 14.6188 9.70678 14.6188Z"
                    fill="white"
                  />
                  <path
                    d="M9.70517 6.59633C7.98823 6.59633 6.59619 7.98822 6.59619 9.70516C6.59619 11.4223 7.98823 12.8141 9.70517 12.8141C11.4223 12.8141 12.8142 11.4223 12.8142 9.70516C12.8142 7.98822 11.4223 6.59633 9.70517 6.59633ZM9.70517 11.7438C8.57925 11.7438 7.66658 10.8311 7.66658 9.70516C7.66658 8.57924 8.57925 7.66657 9.70517 7.66657C10.8311 7.66657 11.7438 8.57924 11.7438 9.70516C11.7438 10.8311 10.8311 11.7438 9.70517 11.7438Z"
                    fill="white"
                  />
                  <path
                    d="M13.7352 6.45446C13.7352 6.86289 13.4041 7.19402 12.9957 7.19402C12.5873 7.19402 12.256 6.86289 12.256 6.45446C12.256 6.04588 12.5873 5.71476 12.9957 5.71476C13.4041 5.71476 13.7352 6.04588 13.7352 6.45446Z"
                    fill="white"
                  />
                </svg>
                <div>{bvalue?.instagram}</div>
              </div>

              <div className="content-sec">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="15"
                  viewBox="0 0 20 15"
                  fill="none"
                >
                  <path
                    d="M19.41 3.79911L17.096 5.06814L14.782 7.2601V14.782H18.0216C18.7885 14.782 19.41 14.1622 19.41 13.3976V3.79911Z"
                    fill="var(--main-color)"
                  />
                  <path
                    d="M0 3.79911L1.67256 4.58822L4.62801 7.2601V14.782H1.3884C0.621541 14.782 0 14.1622 0 13.3976V3.79911Z"
                    fill="var(--main-color)"
                  />
                  <path
                    d="M14.769 1.48512L9.68772 5.2888L4.60641 1.48512L4.14447 4.15922L4.60641 7.24828L9.68772 11.052L14.769 7.24828L15.231 4.15922L14.769 1.48512Z"
                    fill="var(--main-color)"
                  />
                  <path
                    d="M0 1.98552V3.78811L4.62801 7.25285V1.47829L3.18222 0.396828C2.83789 0.139051 2.41952 0 1.98912 0C0.890429 0 0 0.88882 0 1.98552Z"
                    fill="var(--main-color)"
                  />
                  <path
                    d="M19.41 1.98552V3.78811L14.782 7.25285V1.47829L16.2278 0.396828C16.5721 0.139051 16.9905 0 17.4209 0C18.5196 0 19.41 0.88882 19.41 1.98552Z"
                    fill="var(--main-color)"
                  />
                </svg>
                <div
                  onClick={() => window.open(`https://${bvalue?.contactEmail}`)}
                >
                  {bvalue?.contactEmail}
                </div>
              </div>

              <div
                className="content-sec"
                style={{
                  alignItems: "flex-start",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="25"
                  viewBox="0 0 20 25"
                  fill="none"
                >
                  <path
                    d="M9.705 0C4.35358 0 0 4.34741 0 9.69058C0 11.4346 0.449493 13.2841 1.33668 15.1867C2.03404 16.6835 3.00305 18.2186 4.21692 19.7498C6.27212 22.3417 8.30129 24.0137 8.38648 24.0837C8.75603 24.3866 9.23061 24.5381 9.705 24.5381C10.1794 24.5381 10.654 24.3866 11.0235 24.0837C11.1087 24.0137 13.1379 22.3417 15.1931 19.7498C16.407 18.2186 17.376 16.6835 18.0733 15.1867C18.9605 13.2841 19.41 11.4346 19.41 9.69058C19.41 4.34741 15.0564 0 9.705 0V0Z"
                    fill="var(--main-color)"
                    fill-opacity="0.956863"
                  />
                  <path
                    d="M9.70499 3.79921C7.62823 3.79921 5.80573 4.88658 4.77487 6.5229H10.8205C12.2464 6.5229 13.4064 7.68288 13.4064 9.1088C13.4064 10.5347 12.2464 11.6947 10.8205 11.6947H10.2599L12.3056 14.8332C14.216 13.8779 15.528 11.9035 15.528 9.62221C15.528 6.40626 12.9209 3.79921 9.70499 3.79921ZM10.4067 15.403C10.1766 15.4306 9.94247 15.4452 9.70499 15.4452C6.48904 15.4452 3.88199 12.8381 3.88199 9.62221C3.88199 9.2066 3.92588 8.80135 4.00862 8.41038H10.8205C11.2056 8.41038 11.5189 8.72375 11.5189 9.1088C11.5189 9.49386 11.2056 9.80714 10.8205 9.80714H6.76474L10.4067 15.403Z"
                    fill="white"
                  />
                </svg>
                <div
                  style={{
                    textDecoration: "none",
                  }}
                >
                  {bvalue?.officeAddress}
                  <br />
                  {bvalue?.city}, {bvalue?.state}
                  <br />
                  {bvalue?.countryName} {bvalue?.pincode}
                </div>
              </div>
            </div>

            <div className="box3">
              <div className="name">Book Meeting</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
