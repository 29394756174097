export const calculatorcategories = [
  { name: "Travel", icon: "IoAirplaneSharp" },
  { name: "Rental", icon: "IoHome" },
  { name: "Car", icon: "IoCarSportSharp" },
  { name: "Medical", icon: "FaBriefcaseMedical" },
  { name: "Legacy", icon: "MdChildFriendly" },
  { name: "Clothes", icon: "FaHatCowboy" },
  { name: "Construction", icon: "FaPaintRoller" },
  { name: "Payment", icon: "FaWallet" },
];

export const calculatorLeftCard = [
  {
    number: "01",
    content:
      "In Order For Us To Create Your R-PlanB, We Need To Know Where You Stand Today",
  },
  {
    number: "02",
    content:
      "Time To Map Your Dreams Onto The R-PlanB. In The Next Step You Can Be Realistic. For This Step, Let Your Dreams Run Wild.",
  },
  {
    number: "03",
    content:
      "Now Lets Figure Out The Minimum Requirents To Get You CoverFi. Time To Limit The Expediture.",
  },
  {
    number: "04",
    content:
      "Finally Lets Assess Your Risk Tolerance Aganist Your Expectations For Reward Potential",
  },
];
