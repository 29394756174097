import React from "react";
import "./webiste.scss";
import { Link } from "react-router-dom";
import shorpans from "../../assets/shorpans.svg";
import CustomerNav from "../../components/Customnav/Customnav";
export default function webiste() {
  return (
    <>
      <CustomerNav />
      <div className="webfullpage">
        <div className="websitepage">
          <div className="first-content">
            <div className="first-content-first-title">Hi, Im Shorupan</div>
            <div className="first-content-title">
              I’m a certified financial planner
            </div>
            <div className="first-content-para">
              Invest Into Income Generating Retirement Bonds And Build Toward
              Your Financial Freedom With The Help Of CoverFi.App.
            </div>

            <div className="first-section-btn-parent">
              {/* <div className="first-content-btn">
                    <div>
                      <img
                        src={applelogo}
                        alt="applelogo"
                        className="applegrey"
                      />
                      <img
                        src={applelogowhite}
                        alt="applelogowhite"
                        className="applewhite"
                      />
                    </div>
                    <div>
                      <div>Download On</div>
                      <div>App Store</div>
                    </div>
                  </div>
                  <div className="first-content-btn">
                    <div>
                      <img src={playstorelogo} alt="playstorelogo" />
                    </div>
                    <div>
                      <div>Download On</div>
                      <div>Google Play</div>
                    </div>
                  </div> */}
              <Link to="/rplanb/step1" className="landingBtn">
                <span>Create Your PlanB</span>
                <div>
                  {/* <img src={dottedarrow} alt="" />
                      <img src={dottedarrow} alt="" />
                      <img src={dottedarrow} alt="" /> */}
                  {/* <svg
                        width="22"
                        height="21"
                        viewBox="0 0 22 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          clip-path="url(#clip0_1_88)"
                          className="dottedarrowgroup"
                        >
                          <rect width="22" height="21" fill="var(--main-color)" />
                          <path d="M1.18217 2.09285C1.76009 2.09285 2.22859 1.62435 2.22859 1.04643C2.22859 0.468501 1.76009 0 1.18217 0C0.604243 0 0.135742 0.468501 0.135742 1.04643C0.135742 1.62435 0.604243 2.09285 1.18217 2.09285Z" />
                          <path d="M4.28971 5.2638C4.86764 5.2638 5.33614 4.79529 5.33614 4.21737C5.33614 3.63944 4.86764 3.17094 4.28971 3.17094C3.71179 3.17094 3.24329 3.63944 3.24329 4.21737C3.24329 4.79529 3.71179 5.2638 4.28971 5.2638Z" />
                          <path d="M4.28971 2.09285C4.86764 2.09285 5.33614 1.62435 5.33614 1.04643C5.33614 0.468501 4.86764 0 4.28971 0C3.71179 0 3.24329 0.468501 3.24329 1.04643C3.24329 1.62435 3.71179 2.09285 4.28971 2.09285Z" />
                          <path d="M7.46085 5.2638C8.03878 5.2638 8.50728 4.79529 8.50728 4.21737C8.50728 3.63944 8.03878 3.17094 7.46085 3.17094C6.88293 3.17094 6.41443 3.63944 6.41443 4.21737C6.41443 4.79529 6.88293 5.2638 7.46085 5.2638Z" />
                          <path d="M7.46085 8.3714C8.03878 8.3714 8.50728 7.9029 8.50728 7.32497C8.50728 6.74705 8.03878 6.27855 7.46085 6.27855C6.88293 6.27855 6.41443 6.74705 6.41443 7.32497C6.41443 7.9029 6.88293 8.3714 7.46085 8.3714Z" />
                          <path d="M7.46085 2.09285C8.03878 2.09285 8.50728 1.62435 8.50728 1.04643C8.50728 0.468501 8.03878 0 7.46085 0C6.88293 0 6.41443 0.468501 6.41443 1.04643C6.41443 1.62435 6.88293 2.09285 7.46085 2.09285Z" />
                          <path d="M10.5684 5.2638C11.1463 5.2638 11.6148 4.79529 11.6148 4.21737C11.6148 3.63944 11.1463 3.17094 10.5684 3.17094C9.99047 3.17094 9.52197 3.63944 9.52197 4.21737C9.52197 4.79529 9.99047 5.2638 10.5684 5.2638Z" />
                          <path d="M10.5684 8.3714C11.1463 8.3714 11.6148 7.9029 11.6148 7.32497C11.6148 6.74705 11.1463 6.27855 10.5684 6.27855C9.99047 6.27855 9.52197 6.74705 9.52197 7.32497C9.52197 7.9029 9.99047 8.3714 10.5684 8.3714Z" />
                          <path d="M10.5684 2.09285C11.1463 2.09285 11.6148 1.62435 11.6148 1.04643C11.6148 0.468501 11.1463 0 10.5684 0C9.99047 0 9.52197 0.468501 9.52197 1.04643C9.52197 1.62435 9.99047 2.09285 10.5684 2.09285Z" />
                          <path d="M13.6759 5.2638C14.2539 5.2638 14.7224 4.79529 14.7224 4.21737C14.7224 3.63944 14.2539 3.17094 13.6759 3.17094C13.098 3.17094 12.6295 3.63944 12.6295 4.21737C12.6295 4.79529 13.098 5.2638 13.6759 5.2638Z" />
                          <path d="M13.6759 8.3714C14.2539 8.3714 14.7224 7.9029 14.7224 7.32497C14.7224 6.74705 14.2539 6.27855 13.6759 6.27855C13.098 6.27855 12.6295 6.74705 12.6295 7.32497C12.6295 7.9029 13.098 8.3714 13.6759 8.3714Z" />
                          <path d="M10.5684 11.4791C11.1463 11.4791 11.6148 11.0106 11.6148 10.4326C11.6148 9.8547 11.1463 9.3862 10.5684 9.3862C9.99047 9.3862 9.52197 9.8547 9.52197 10.4326C9.52197 11.0106 9.99047 11.4791 10.5684 11.4791Z" />
                          <path d="M13.6759 11.4791C14.2539 11.4791 14.7224 11.0106 14.7224 10.4326C14.7224 9.8547 14.2539 9.3862 13.6759 9.3862C13.098 9.3862 12.6295 9.8547 12.6295 10.4326C12.6295 11.0106 13.098 11.4791 13.6759 11.4791Z" />
                          <path d="M7.4608 14.65C8.03877 14.65 8.5073 14.1815 8.5073 13.6035C8.5073 13.0256 8.03877 12.557 7.4608 12.557C6.88284 12.557 6.41431 13.0256 6.41431 13.6035C6.41431 14.1815 6.88284 14.65 7.4608 14.65Z" />
                          <path d="M10.5683 14.65C11.1463 14.65 11.6148 14.1815 11.6148 13.6035C11.6148 13.0256 11.1463 12.557 10.5683 12.557C9.99038 12.557 9.52185 13.0256 9.52185 13.6035C9.52185 14.1815 9.99038 14.65 10.5683 14.65Z" />
                          <path d="M13.676 14.65C14.254 14.65 14.7225 14.1815 14.7225 13.6035C14.7225 13.0256 14.254 12.557 13.676 12.557C13.098 12.557 12.6295 13.0256 12.6295 13.6035C12.6295 14.1815 13.098 14.65 13.676 14.65Z" />
                          <path d="M4.28971 17.7615C4.86764 17.7615 5.33614 17.293 5.33614 16.7151C5.33614 16.1372 4.86764 15.6687 4.28971 15.6687C3.71179 15.6687 3.24329 16.1372 3.24329 16.7151C3.24329 17.293 3.71179 17.7615 4.28971 17.7615Z" />
                          <path d="M7.46085 17.7615C8.03878 17.7615 8.50728 17.293 8.50728 16.7151C8.50728 16.1372 8.03878 15.6687 7.46085 15.6687C6.88293 15.6687 6.41443 16.1372 6.41443 16.7151C6.41443 17.293 6.88293 17.7615 7.46085 17.7615Z" />
                          <path d="M10.5684 17.7615C11.1463 17.7615 11.6148 17.293 11.6148 16.7151C11.6148 16.1372 11.1463 15.6687 10.5684 15.6687C9.99047 15.6687 9.52197 16.1372 9.52197 16.7151C9.52197 17.293 9.99047 17.7615 10.5684 17.7615Z" />
                          <path d="M13.6759 17.7615C14.2539 17.7615 14.7224 17.293 14.7224 16.7151C14.7224 16.1372 14.2539 15.6687 13.6759 15.6687C13.098 15.6687 12.6295 16.1372 12.6295 16.7151C12.6295 17.293 13.098 17.7615 13.6759 17.7615Z" />
                          <path d="M16.9143 8.3714C17.4923 8.3714 17.9608 7.9029 17.9608 7.32497C17.9608 6.74705 17.4923 6.27855 16.9143 6.27855C16.3364 6.27855 15.8679 6.74705 15.8679 7.32497C15.8679 7.9029 16.3364 8.3714 16.9143 8.3714Z" />
                          <path d="M16.9143 11.4791C17.4923 11.4791 17.9608 11.0106 17.9608 10.4326C17.9608 9.8547 17.4923 9.3862 16.9143 9.3862C16.3364 9.3862 15.8679 9.8547 15.8679 10.4326C15.8679 11.0106 16.3364 11.4791 16.9143 11.4791Z" />
                          <path d="M16.9144 14.65C17.4924 14.65 17.9609 14.1815 17.9609 13.6035C17.9609 13.0256 17.4924 12.557 16.9144 12.557C16.3365 12.557 15.8679 13.0256 15.8679 13.6035C15.8679 14.1815 16.3365 14.65 16.9144 14.65Z" />
                          <path d="M1.18212 21C1.76008 21 2.22861 20.5315 2.22861 19.9535C2.22861 19.3755 1.76008 18.907 1.18212 18.907C0.604152 18.907 0.13562 19.3755 0.13562 19.9535C0.13562 20.5315 0.604152 21 1.18212 21Z" />
                          <path d="M4.28978 21C4.86775 21 5.33628 20.5315 5.33628 19.9535C5.33628 19.3755 4.86775 18.907 4.28978 18.907C3.71182 18.907 3.24329 19.3755 3.24329 19.9535C3.24329 20.5315 3.71182 21 4.28978 21Z" />
                          <path d="M7.4608 21C8.03877 21 8.5073 20.5315 8.5073 19.9535C8.5073 19.3755 8.03877 18.907 7.4608 18.907C6.88284 18.907 6.41431 19.3755 6.41431 19.9535C6.41431 20.5315 6.88284 21 7.4608 21Z" />
                          <path d="M10.5683 21C11.1463 21 11.6148 20.5315 11.6148 19.9535C11.6148 19.3755 11.1463 18.907 10.5683 18.907C9.99038 18.907 9.52185 19.3755 9.52185 19.9535C9.52185 20.5315 9.99038 21 10.5683 21Z" />
                          <path d="M20.0259 11.4791C20.6038 11.4791 21.0723 11.0106 21.0723 10.4326C21.0723 9.8547 20.6038 9.3862 20.0259 9.3862C19.448 9.3862 18.9795 9.8547 18.9795 10.4326C18.9795 11.0106 19.448 11.4791 20.0259 11.4791Z" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_88">
                            <rect width="22" height="21" fill="white" />
                          </clipPath>
                        </defs>
                      </svg> */}
                  <ArrowComponent delay={"0s"} />
                  <ArrowComponent delay={"0.2s"} />
                  <ArrowComponent delay={"0.4s"} />
                </div>
              </Link>
            </div>
          </div>
          {/* <div className="mob-image-first-section">

            </div> */}
          <div
            style={{
              // padding: window.location.pathname == "/coach" ? "15rem 0 0" : "",
              alignItems: "end",
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              paddingLeft: "15rem",
            }}
          >
            <img
              src={shorpans}
              style={{
                height: "49rem",
              }}
              alt="firstsectionlady"
            />
          </div>
        </div>
        <div className="tabwebiste">
          <div className="title">
            Coverage Type
            <div className="borderlisss"></div>
          </div>
          <div className="title">Coverage Details</div>
          <div className="title">Policy Size</div>
          <div className="title">Cost Estimation</div>
        </div>
        <div className="websitecard">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="73"
              height="70"
              viewBox="0 0 73 70"
              fill="none"
            >
              <path
                d="M72.3333 35C72.3333 15.67 56.141 0 36.1667 0C16.1924 0 0 15.67 0 35C0 54.33 16.1924 70 36.1667 70C56.141 70 72.3333 54.33 72.3333 35Z"
                fill="#110F0D"
              />
              <path
                opacity="0.6"
                d="M35.5487 28.2237L19.5349 35.4813L35.5487 44.9335L51.5818 35.4813L35.5487 28.2237Z"
                fill="white"
              />
              <path
                opacity="0.45"
                d="M19.5349 35.4813L35.5487 44.9335V8.95349L19.5349 35.4813Z"
                fill="white"
              />
              <path
                opacity="0.8"
                d="M35.549 8.95349V44.9335L51.582 35.4813L35.549 8.95349Z"
                fill="white"
              />
              <path
                opacity="0.45"
                d="M19.5349 38.5229L35.5487 61.0465V47.9558L19.5349 38.5229Z"
                fill="white"
              />
              <path
                opacity="0.8"
                d="M35.549 47.9558V61.0465L51.582 38.5229L35.549 47.9558Z"
                fill="white"
              />
            </svg>
          </div>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="73"
              height="70"
              viewBox="0 0 73 70"
              fill="none"
            >
              <path
                d="M72.4351 16.9324V27.1978C72.4351 25.824 71.1082 24.7001 69.4641 24.7001H6.11045C3.0305 24.7001 0.485252 22.7853 0.0693662 20.3043C0.0197523 20.0629 0 19.8213 0 19.5716C0 18.1478 0.683242 16.8658 1.79263 15.9417C2.89176 15.0092 4.41704 14.4347 6.11045 14.4347H69.4641C71.1082 14.4347 72.4351 15.5586 72.4351 16.9324Z"
                fill="#3A556A"
              />
              <path
                d="M26.9861 32.9654C36.0893 32.9654 43.4689 25.5859 43.4689 16.4827C43.4689 7.37957 36.0893 0 26.9861 0C17.883 0 10.5034 7.37957 10.5034 16.4827C10.5034 25.5859 17.883 32.9654 26.9861 32.9654Z"
                fill="#E56353"
              />
              <path
                d="M30.3791 16.4828C31.5407 15.5988 32.2213 14.2427 32.2213 12.7686C32.2213 10.1881 30.1218 8.08857 27.5413 8.08857H27.0151V6.15689H26.0493V8.08857H24.9225V6.15689H23.9567V8.08857H22.9908H21.7517V10.0202H22.9908V15.517H21.7517V17.4487H22.9908V22.9454H21.7517V24.8771H22.9908H23.9567V26.8087H24.9225V24.8771H26.0493V26.8087H27.0151V24.8771H27.5413C30.1218 24.8771 32.2213 22.7776 32.2213 20.197C32.2213 18.7229 31.5407 17.3668 30.3791 16.4828ZM27.5415 22.9454H24.9228V17.4487H27.5415C29.0568 17.4487 30.2898 18.6817 30.2898 20.197C30.2898 21.7123 29.0568 22.9454 27.5415 22.9454ZM27.5415 15.517H24.9228V10.0202H27.5415C29.0568 10.0202 30.2898 11.2533 30.2898 12.7686C30.2898 14.2841 29.0568 15.517 27.5415 15.517Z"
                fill="#E1E6E9"
              />
              <path
                d="M49.3082 37.6458C57.8429 37.6458 64.7615 30.7271 64.7615 22.1925C64.7615 13.6579 57.8429 6.73923 49.3082 6.73923C40.7736 6.73923 33.855 13.6579 33.855 22.1925C33.855 30.7271 40.7736 37.6458 49.3082 37.6458Z"
                fill="#31978C"
              />
              <path
                d="M52.0257 22.1916C52.955 21.4844 53.4995 20.3995 53.4995 19.2202C53.4995 17.1558 51.82 15.4761 49.7555 15.4761H49.3346V13.9308H48.5619V15.4761H47.6605V13.9308H46.8878V15.4761H46.1151H45.1238V17.0214H46.1151V21.4189H45.1238V22.9642H46.1151V27.3617H45.1238V28.907H46.1151H46.8878V30.4524H47.6605V28.907H48.5619V30.4524H49.3346V28.907H49.7555C51.8198 28.907 53.4995 27.2275 53.4995 25.163C53.4995 23.9836 52.955 22.8988 52.0257 22.1916ZM49.7555 27.3617H47.6605V22.9642H49.7555C50.9678 22.9642 51.9542 23.9506 51.9542 25.163C51.9542 26.3751 50.9678 27.3617 49.7555 27.3617ZM49.7555 21.4189H47.6605V17.0214H49.7555C50.9678 17.0214 51.9542 18.0078 51.9542 19.2202C51.9542 20.4325 50.9678 21.4189 49.7555 21.4189Z"
                fill="#EBF0F3"
              />
              <path
                d="M72.4351 27.1979V67.5024C72.4351 68.8844 71.1082 70 69.4641 70H5.94217C2.66407 70 0 67.7688 0 65.0047V20.296L0.0693662 20.3044C0.485252 22.7854 3.0305 24.7003 6.11045 24.7003H69.4641C71.1082 24.7003 72.4351 25.8243 72.4351 27.1979Z"
                fill="#64798A"
              />
              <path
                d="M72.4351 53.2106H56.6101L52.3044 47.3503L56.6101 41.4899H72.4351V53.2106Z"
                fill="#110F0D"
              />
              <path
                d="M61.1169 50.7341C62.9838 50.7341 64.4973 49.2206 64.4973 47.3537C64.4973 45.4867 62.9838 43.9732 61.1169 43.9732C59.2499 43.9732 57.7365 45.4867 57.7365 47.3537C57.7365 49.2206 59.2499 50.7341 61.1169 50.7341Z"
                fill="white"
              />
            </svg>
          </div>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="77"
              height="70"
              viewBox="0 0 77 70"
              fill="none"
            >
              <path
                d="M15.909 35C20.2935 35 23.8634 31.4301 23.8634 27.0456C23.8634 22.661 20.2935 19.0911 15.909 19.0911C11.5244 19.0911 7.95447 22.661 7.95447 27.0456C7.95447 31.4301 11.5244 35 15.909 35Z"
                fill="#31978C"
              />
              <path
                d="M23.068 38.1818H8.74993C3.92315 38.1818 0 42.105 0 46.9318V48.5227C0 49.8399 1.06908 50.909 2.38635 50.909H29.4316C30.7489 50.909 31.8179 49.8399 31.8179 48.5227V46.9318C31.8179 42.105 27.8948 38.1818 23.068 38.1818Z"
                fill="#31978C"
              />
              <path
                d="M61.4404 13.5547C60.8836 13.5547 60.3205 13.3607 59.8686 12.9629C53.8455 7.68116 46.1424 4.773 38.1815 4.773C30.6789 4.773 23.3416 7.40434 17.519 12.1866C16.4976 13.0234 14.9926 12.8738 14.159 11.8557C13.3222 10.8375 13.4717 9.33251 14.4899 8.4957C21.1621 3.01665 29.578 0.000305176 38.1815 0.000305176C47.3006 0.000305176 56.1205 3.32846 63.0154 9.37387C64.005 10.2425 64.1036 11.7507 63.235 12.7434C62.7641 13.2779 62.1023 13.5547 61.4404 13.5547Z"
                fill="#64798A"
              />
              <path
                d="M23.068 12.7274H15.1135C13.7963 12.7274 12.7272 11.6583 12.7272 10.3411V2.38659C12.7272 1.42251 13.3094 0.550695 14.2003 0.181606C15.0912 -0.187482 16.119 0.0161531 16.7999 0.700239L24.7544 8.65473C25.4353 9.33563 25.6421 10.3633 25.273 11.2543C24.9039 12.1452 24.0321 12.7274 23.068 12.7274Z"
                fill="#64798A"
              />
              <path
                d="M38.1815 0.000305176C32.0152 0.000305176 25.9475 1.54984 20.5448 4.44527L22.3202 6.22071C28.2002 3.50664 33.6188 2.47574 38.4583 2.47574C53.7309 2.47574 63.2317 12.7402 63.2317 12.7402C64.1004 11.7475 64.0017 10.2393 63.0122 9.37069C56.1204 3.32846 47.3005 0.000305176 38.1815 0.000305176Z"
                fill="#64798A"
              />
              <path
                d="M15.1103 0.000305176C14.8049 0.000305176 14.4931 0.0607594 14.2003 0.181668C13.3094 0.550756 12.7272 1.42257 12.7272 2.38665V10.3411C12.7272 10.8311 12.8767 11.2893 13.1281 11.6679C16.2971 9.37387 19.3676 7.5857 22.3203 6.22071L20.5448 4.44527L16.7999 0.7003C16.3449 0.242122 15.734 0.000305176 15.1103 0.000305176Z"
                fill="#64798A"
              />
              <path
                d="M15.1135 56.4453C15.6703 56.4453 16.2335 56.6394 16.6853 57.0371C22.7084 62.3189 30.4084 65.227 38.3692 65.227C45.8719 65.227 53.2091 62.5957 59.0318 57.8135C60.0531 56.9735 61.5549 57.123 62.3918 58.1444C63.2286 59.1625 63.079 60.6675 62.0608 61.5043C55.3918 66.9834 46.976 69.9997 38.3724 69.9997C29.2534 69.9997 20.4334 66.6716 13.5385 60.6262C12.549 59.7575 12.4503 58.2494 13.3189 57.2566C13.7899 56.7221 14.4517 56.4453 15.1135 56.4453Z"
                fill="#64798A"
              />
              <path
                d="M53.486 57.2726H61.4405C62.7577 57.2726 63.8268 58.3417 63.8268 59.6589V67.6134C63.8268 68.5775 63.2446 69.4493 62.3536 69.8184C61.4627 70.1875 60.435 69.9839 59.7541 69.2998L51.7996 61.3453C51.1187 60.6644 50.9119 59.6367 51.281 58.7458C51.6501 57.8548 52.5219 57.2726 53.486 57.2726Z"
                fill="#64798A"
              />
              <path
                d="M38.3724 69.9997C44.5387 69.9997 50.6064 68.4502 56.0091 65.5548L54.2336 63.7793C48.3537 66.4934 42.9351 67.5243 38.0956 67.5243C22.823 67.5243 13.3221 57.2598 13.3221 57.2598C12.4535 58.2525 12.5521 59.7607 13.5417 60.6293C20.4334 66.6716 29.2534 69.9997 38.3724 69.9997Z"
                fill="#64798A"
              />
              <path
                d="M61.4436 69.9998C61.7522 69.9998 62.0608 69.9393 62.3568 69.8184C63.2477 69.4493 63.8299 68.5775 63.8299 67.6134V59.6589C63.8299 59.1689 63.6804 58.7108 63.429 58.3321C60.26 60.6262 57.1895 62.4144 54.2368 63.7794L56.0123 65.5548L59.7572 69.2998C60.209 69.7579 60.82 69.9998 61.4436 69.9998Z"
                fill="#64798A"
              />
              <path
                d="M60.4541 35C64.8386 35 68.4086 31.4301 68.4086 27.0456C68.4086 22.661 64.8386 19.0911 60.4541 19.0911C56.0696 19.0911 52.4996 22.661 52.4996 27.0456C52.4996 31.4301 56.0696 35 60.4541 35Z"
                fill="#E56353"
              />
              <path
                d="M67.6131 38.1818H53.295C48.4682 38.1818 44.545 42.105 44.545 46.9318V48.5227C44.545 49.8399 45.6141 50.909 46.9314 50.909H73.9766C75.2939 50.909 76.363 49.8399 76.363 48.5227V46.9318C76.363 42.105 72.4398 38.1818 67.6131 38.1818Z"
                fill="#E56353"
              />
            </svg>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="69"
              height="70"
              viewBox="0 0 69 70"
              fill="none"
            >
              <path
                d="M10.3826 16.4613V5.11423H2.396V66.0047H51.9126V58.0137C28.9761 58.0137 10.3826 39.41 10.3826 16.4613Z"
                fill="#E6E7E8"
              />
              <path
                d="M54.2784 38.3562H49.4865V8.22878C49.4865 7.78709 49.1292 7.42767 48.6899 7.42767H43.6844V2.63312H48.6899C51.7714 2.63312 54.2784 5.14325 54.2784 8.22878V38.3562Z"
                fill="#110F0D"
              />
              <path
                d="M38.8147 68.3749H5.58878C2.50699 68.3749 0 65.8646 0 62.7792V8.22875C0 5.14336 2.50699 2.63309 5.58878 2.63309H11.47V7.42764H5.58878C5.14934 7.42764 4.79201 7.78706 4.79201 8.22875V62.7792C4.79201 63.2209 5.14934 63.5805 5.58878 63.5805H38.8147V68.3749Z"
                fill="#110F0D"
              />
              <path
                d="M36.4194 9.91487H17.8589C16.372 9.91487 15.1666 8.70947 15.1666 7.22256V2.69231C15.1666 1.20541 16.372 0 17.8589 0H36.4194C37.9063 0 39.1117 1.20541 39.1117 2.69231V7.22256C39.1117 8.70947 37.9063 9.91487 36.4194 9.91487Z"
                fill="#110F0D"
              />
              <path
                d="M27.9417 36.7285H45.2007V41.523H27.9417V36.7285Z"
                fill="#110F0D"
              />
              <path
                d="M27.9417 22.3355H45.2007V27.1301H27.9417V22.3355Z"
                fill="#110F0D"
              />
              <path
                d="M27.9417 51.1213H35.6168V55.9158H27.9417V51.1213Z"
                fill="#110F0D"
              />
              <path
                d="M54.6362 70C62.1301 70 68.2051 63.9141 68.2051 56.4067C68.2051 48.8993 62.1301 42.8134 54.6362 42.8134C47.1423 42.8134 41.0673 48.8993 41.0673 56.4067C41.0673 63.9141 47.1423 70 54.6362 70Z"
                fill="#110F0D"
              />
              <path
                d="M57.97 63.0193L52.9322 57.8504C52.4974 57.4044 52.2533 56.8065 52.2517 56.1836L52.23 48.5588L57.0218 48.5452L57.0407 55.1985L61.4007 59.6719L57.97 63.0193Z"
                fill="white"
              />
              <path
                d="M12.8018 58.2071L8.14893 53.1364L11.6802 49.8986L14.568 53.0506L21.432 45.5666L24.9624 48.8085L16.3326 58.2075C15.879 58.7022 14.2136 59.532 12.8018 58.2071Z"
                fill="#110F0D"
              />
              <path
                d="M12.8018 43.8236L8.14893 38.7529L11.6802 35.5151L14.568 38.6671L21.432 31.183L24.9624 34.425L16.3326 43.824C15.879 44.3185 14.2136 45.1483 12.8018 43.8236Z"
                fill="#110F0D"
              />
              <path
                d="M12.8018 29.2802L8.14893 24.2095L11.6802 20.9717L14.568 24.1237L21.432 16.6396L24.9624 19.8816L16.3326 29.2806C15.879 29.7753 14.2136 30.6049 12.8018 29.2802Z"
                fill="#110F0D"
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}

function ArrowComponent({ delay }) {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clip-path="url(#clip0_1_88)"
        className="dottedarrowgroup"
        style={{ animationDelay: delay }}
      >
        <rect width="22" height="21" fill="var(--main-color)" />
        <path d="M1.18217 2.09285C1.76009 2.09285 2.22859 1.62435 2.22859 1.04643C2.22859 0.468501 1.76009 0 1.18217 0C0.604243 0 0.135742 0.468501 0.135742 1.04643C0.135742 1.62435 0.604243 2.09285 1.18217 2.09285Z" />
        <path d="M4.28971 5.2638C4.86764 5.2638 5.33614 4.79529 5.33614 4.21737C5.33614 3.63944 4.86764 3.17094 4.28971 3.17094C3.71179 3.17094 3.24329 3.63944 3.24329 4.21737C3.24329 4.79529 3.71179 5.2638 4.28971 5.2638Z" />
        <path d="M4.28971 2.09285C4.86764 2.09285 5.33614 1.62435 5.33614 1.04643C5.33614 0.468501 4.86764 0 4.28971 0C3.71179 0 3.24329 0.468501 3.24329 1.04643C3.24329 1.62435 3.71179 2.09285 4.28971 2.09285Z" />
        <path d="M7.46085 5.2638C8.03878 5.2638 8.50728 4.79529 8.50728 4.21737C8.50728 3.63944 8.03878 3.17094 7.46085 3.17094C6.88293 3.17094 6.41443 3.63944 6.41443 4.21737C6.41443 4.79529 6.88293 5.2638 7.46085 5.2638Z" />
        <path d="M7.46085 8.3714C8.03878 8.3714 8.50728 7.9029 8.50728 7.32497C8.50728 6.74705 8.03878 6.27855 7.46085 6.27855C6.88293 6.27855 6.41443 6.74705 6.41443 7.32497C6.41443 7.9029 6.88293 8.3714 7.46085 8.3714Z" />
        <path d="M7.46085 2.09285C8.03878 2.09285 8.50728 1.62435 8.50728 1.04643C8.50728 0.468501 8.03878 0 7.46085 0C6.88293 0 6.41443 0.468501 6.41443 1.04643C6.41443 1.62435 6.88293 2.09285 7.46085 2.09285Z" />
        <path d="M10.5684 5.2638C11.1463 5.2638 11.6148 4.79529 11.6148 4.21737C11.6148 3.63944 11.1463 3.17094 10.5684 3.17094C9.99047 3.17094 9.52197 3.63944 9.52197 4.21737C9.52197 4.79529 9.99047 5.2638 10.5684 5.2638Z" />
        <path d="M10.5684 8.3714C11.1463 8.3714 11.6148 7.9029 11.6148 7.32497C11.6148 6.74705 11.1463 6.27855 10.5684 6.27855C9.99047 6.27855 9.52197 6.74705 9.52197 7.32497C9.52197 7.9029 9.99047 8.3714 10.5684 8.3714Z" />
        <path d="M10.5684 2.09285C11.1463 2.09285 11.6148 1.62435 11.6148 1.04643C11.6148 0.468501 11.1463 0 10.5684 0C9.99047 0 9.52197 0.468501 9.52197 1.04643C9.52197 1.62435 9.99047 2.09285 10.5684 2.09285Z" />
        <path d="M13.6759 5.2638C14.2539 5.2638 14.7224 4.79529 14.7224 4.21737C14.7224 3.63944 14.2539 3.17094 13.6759 3.17094C13.098 3.17094 12.6295 3.63944 12.6295 4.21737C12.6295 4.79529 13.098 5.2638 13.6759 5.2638Z" />
        <path d="M13.6759 8.3714C14.2539 8.3714 14.7224 7.9029 14.7224 7.32497C14.7224 6.74705 14.2539 6.27855 13.6759 6.27855C13.098 6.27855 12.6295 6.74705 12.6295 7.32497C12.6295 7.9029 13.098 8.3714 13.6759 8.3714Z" />
        <path d="M10.5684 11.4791C11.1463 11.4791 11.6148 11.0106 11.6148 10.4326C11.6148 9.8547 11.1463 9.3862 10.5684 9.3862C9.99047 9.3862 9.52197 9.8547 9.52197 10.4326C9.52197 11.0106 9.99047 11.4791 10.5684 11.4791Z" />
        <path d="M13.6759 11.4791C14.2539 11.4791 14.7224 11.0106 14.7224 10.4326C14.7224 9.8547 14.2539 9.3862 13.6759 9.3862C13.098 9.3862 12.6295 9.8547 12.6295 10.4326C12.6295 11.0106 13.098 11.4791 13.6759 11.4791Z" />
        <path d="M7.4608 14.65C8.03877 14.65 8.5073 14.1815 8.5073 13.6035C8.5073 13.0256 8.03877 12.557 7.4608 12.557C6.88284 12.557 6.41431 13.0256 6.41431 13.6035C6.41431 14.1815 6.88284 14.65 7.4608 14.65Z" />
        <path d="M10.5683 14.65C11.1463 14.65 11.6148 14.1815 11.6148 13.6035C11.6148 13.0256 11.1463 12.557 10.5683 12.557C9.99038 12.557 9.52185 13.0256 9.52185 13.6035C9.52185 14.1815 9.99038 14.65 10.5683 14.65Z" />
        <path d="M13.676 14.65C14.254 14.65 14.7225 14.1815 14.7225 13.6035C14.7225 13.0256 14.254 12.557 13.676 12.557C13.098 12.557 12.6295 13.0256 12.6295 13.6035C12.6295 14.1815 13.098 14.65 13.676 14.65Z" />
        <path d="M4.28971 17.7615C4.86764 17.7615 5.33614 17.293 5.33614 16.7151C5.33614 16.1372 4.86764 15.6687 4.28971 15.6687C3.71179 15.6687 3.24329 16.1372 3.24329 16.7151C3.24329 17.293 3.71179 17.7615 4.28971 17.7615Z" />
        <path d="M7.46085 17.7615C8.03878 17.7615 8.50728 17.293 8.50728 16.7151C8.50728 16.1372 8.03878 15.6687 7.46085 15.6687C6.88293 15.6687 6.41443 16.1372 6.41443 16.7151C6.41443 17.293 6.88293 17.7615 7.46085 17.7615Z" />
        <path d="M10.5684 17.7615C11.1463 17.7615 11.6148 17.293 11.6148 16.7151C11.6148 16.1372 11.1463 15.6687 10.5684 15.6687C9.99047 15.6687 9.52197 16.1372 9.52197 16.7151C9.52197 17.293 9.99047 17.7615 10.5684 17.7615Z" />
        <path d="M13.6759 17.7615C14.2539 17.7615 14.7224 17.293 14.7224 16.7151C14.7224 16.1372 14.2539 15.6687 13.6759 15.6687C13.098 15.6687 12.6295 16.1372 12.6295 16.7151C12.6295 17.293 13.098 17.7615 13.6759 17.7615Z" />
        <path d="M16.9143 8.3714C17.4923 8.3714 17.9608 7.9029 17.9608 7.32497C17.9608 6.74705 17.4923 6.27855 16.9143 6.27855C16.3364 6.27855 15.8679 6.74705 15.8679 7.32497C15.8679 7.9029 16.3364 8.3714 16.9143 8.3714Z" />
        <path d="M16.9143 11.4791C17.4923 11.4791 17.9608 11.0106 17.9608 10.4326C17.9608 9.8547 17.4923 9.3862 16.9143 9.3862C16.3364 9.3862 15.8679 9.8547 15.8679 10.4326C15.8679 11.0106 16.3364 11.4791 16.9143 11.4791Z" />
        <path d="M16.9144 14.65C17.4924 14.65 17.9609 14.1815 17.9609 13.6035C17.9609 13.0256 17.4924 12.557 16.9144 12.557C16.3365 12.557 15.8679 13.0256 15.8679 13.6035C15.8679 14.1815 16.3365 14.65 16.9144 14.65Z" />
        <path d="M1.18212 21C1.76008 21 2.22861 20.5315 2.22861 19.9535C2.22861 19.3755 1.76008 18.907 1.18212 18.907C0.604152 18.907 0.13562 19.3755 0.13562 19.9535C0.13562 20.5315 0.604152 21 1.18212 21Z" />
        <path d="M4.28978 21C4.86775 21 5.33628 20.5315 5.33628 19.9535C5.33628 19.3755 4.86775 18.907 4.28978 18.907C3.71182 18.907 3.24329 19.3755 3.24329 19.9535C3.24329 20.5315 3.71182 21 4.28978 21Z" />
        <path d="M7.4608 21C8.03877 21 8.5073 20.5315 8.5073 19.9535C8.5073 19.3755 8.03877 18.907 7.4608 18.907C6.88284 18.907 6.41431 19.3755 6.41431 19.9535C6.41431 20.5315 6.88284 21 7.4608 21Z" />
        <path d="M10.5683 21C11.1463 21 11.6148 20.5315 11.6148 19.9535C11.6148 19.3755 11.1463 18.907 10.5683 18.907C9.99038 18.907 9.52185 19.3755 9.52185 19.9535C9.52185 20.5315 9.99038 21 10.5683 21Z" />
        <path d="M20.0259 11.4791C20.6038 11.4791 21.0723 11.0106 21.0723 10.4326C21.0723 9.8547 20.6038 9.3862 20.0259 9.3862C19.448 9.3862 18.9795 9.8547 18.9795 10.4326C18.9795 11.0106 19.448 11.4791 20.0259 11.4791Z" />
      </g>
      <defs>
        <clipPath id="clip0_1_88">
          <rect width="22" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
