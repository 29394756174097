import React from "react";
import "./thirdsection.css";

//assets
import sectionthree from "../../../../assets/Images/Home/sectionthree.jpg";
import { bonds } from "../../../../assets/Func/navigations";

const ThirdSection = () => {
  return (
    <section className="third-section">
      <div className="third-section-content">
        <div>
          <img src={sectionthree} alt="sectionthree" />
        </div>
        <div className="third-section-content-last">
          <div>
            Invest in fixed Bond instruments{" "}
            <span style={{ color: "var(--main-color)", width: "100%" }}>
              offering 12-24% returns.
            </span>
          </div>
          <div>
            Earn fixed returns while investing in the corporate bonds that beat
            inflation, starting at just ₹5,000.
          </div>
          <div onClick={bonds}>Explore Bonds to Invest</div>
        </div>
      </div>
    </section>
  );
};

export default ThirdSection;
