import React, { useState } from "react";
import "./faq.css";
// import plus from "../../../assets/images/plus.svg";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

const Faq = () => {
  const [faqOpen1, setFaqOpen1] = useState(false);
  const [faqOpen2, setFaqOpen2] = useState(false);
  const [faqOpen3, setFaqOpen3] = useState(false);
  const [faqOpen4, setFaqOpen4] = useState(false);
  const [faqOpen5, setFaqOpen5] = useState(false);
  const [faqOpen6, setFaqOpen6] = useState(false);

  const faqToggleFunc = (event) => {
    switch (event.target.className) {
      case "firstfaq":
        setfaq();
        setFaqOpen1(!faqOpen1);
        break;
      case "secondfaq":
        setfaq();
        setFaqOpen2(!faqOpen2);
        break;
      case "thirdfaq":
        setfaq();
        setFaqOpen3(!faqOpen3);
        break;
      case "fourthfaq":
        setfaq();
        setFaqOpen4(!faqOpen4);
        break;
      case "fifthfaq":
        setfaq();
        setFaqOpen5(!faqOpen5);
        break;
      case "sixthfaq":
        setfaq();
        setFaqOpen6(!faqOpen6);
        break;
      default:
        break;
    }

    //checking

    switch (event.target.parentElement.classList[0]) {
      case "firstfaq":
        setfaq();
        setFaqOpen1(!faqOpen1);
        break;
      case "secondfaq":
        setfaq();
        setFaqOpen2(!faqOpen2);
        break;
      case "thirdfaq":
        setfaq();
        setFaqOpen3(!faqOpen3);
        break;
      case "fourthfaq":
        setfaq();
        setFaqOpen4(!faqOpen4);
        break;
      case "fifthfaq":
        setfaq();
        setFaqOpen5(!faqOpen5);
        break;
      case "sixthfaq":
        setfaq();
        setFaqOpen6(!faqOpen6);
        break;
      default:
        break;
    }

    function setfaq() {
      setFaqOpen1(false);
      setFaqOpen2(false);
      setFaqOpen3(false);
      setFaqOpen4(false);
      setFaqOpen5(false);
      setFaqOpen6(false);
    }
  };
  return (
    <section className="faq-section">
      <div className="faq-heading">FAQ’s</div>
      <div className="faq-container">
        <div>
          <div className="firstfaq" onClick={faqToggleFunc}>
            What Is CoverFi App?
            {!faqOpen1 ? <AiOutlinePlus /> : <AiOutlineMinus />}
          </div>
          {faqOpen1 ? (
            <div>
              CoverFi app has a pre-eminent technology specially curated Defi
              Powered platform which leverages the Defi pools to provide massive
              yields to the investors.
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <div className="secondfaq" onClick={faqToggleFunc}>
            How does the debt market work exactly?
            {!faqOpen2 ? <AiOutlinePlus /> : <AiOutlineMinus />}
          </div>
          {faqOpen2 ? (
            <div>
              Debt investment in startups is more than a trillion dollar in
              India, which means the country can be seen as one of the most
              important ecosystems of supporting startups. The strong cultures
              and opportunities to invest in India are certainly factors that
              make foreign investors keen on getting into the market.
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <div className="thirdfaq" onClick={faqToggleFunc}>
            What are bonds?
            {!faqOpen3 ? <AiOutlinePlus /> : <AiOutlineMinus />}
          </div>
          {faqOpen3 ? (
            <div>
              Bonds are fixed-income securities that are issued by private
              institutions to raise funds through the debt market. The investors
              earn returns on their investment during a pre-decided tenure.
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <div className="fourthfaq" onClick={faqToggleFunc}>
            Where do bonds fit in your portfolio?
            {!faqOpen4 ? <AiOutlinePlus /> : <AiOutlineMinus />}
          </div>
          {faqOpen4 ? (
            <div>
              Bonds open up a new asset class for your portfolio. An option with
              lower risk profile (compared to the equity market) and pre-decided
              returns. So, investing in bonds can add stability to your
              portfolio with fixed returns without you having to settle for
              lower returns.
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <div className="fifthfaq" onClick={faqToggleFunc}>
            How Do I Start Investing?
            {!faqOpen5 ? <AiOutlinePlus /> : <AiOutlineMinus />}
          </div>
          {faqOpen5 ? (
            <div>
              You can start investing with CoverFi App in just three steps.
              <br />
              <br />
              1. Sign up with CoverFi App via Gmail see "Sign in" at the top
              right of this page <br />
              2. Browse our supported assets, find the asset you'd like to
              deposit, and click "Invest."
              <br />
              3. Once you click "Invest," a share card will appear with the
              options to "Deposit," "Delete," or "Swap." Your first reward will
              pay out exactly 24 hours after your first deposit. Our "Swap"
              feature allows you to swap between supported assets in just a few
              simple clicks.
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <div className="sixthfaq" onClick={faqToggleFunc}>
            Is there a limit how much i can invest?
            {!faqOpen6 ? <AiOutlinePlus /> : <AiOutlineMinus />}
          </div>
          {faqOpen6 ? (
            <div>
              No, there is not a limit on how much an investor can invest.
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
};

export default Faq;
