import React, { useState } from "react";
import {
  NewUIMyEachInput,
  NewDateAndPicker,
  NewEachCustomDropdown,
} from "./GloabelInput";
import Header from "../Advisors/AdvisorProfile/Header";
import AdvisorSidebar from "../../components/AdvisorSidebar/AdvisorSidebar";
import UssrProfile from "./UssrProfile";
import AdvisorProfile from "./AdivisorProfileeidt";
import ProductsAndServices from "./subpages/ProductsAndServices";
import AdvisorSidebarLatest from "../../components/AdvisorSidebarLatest";
export default function Editing() {
  const [tabName, settabName] = useState("User Profile"); //default "User Profile"
  return (
    <div className="advisor-profile-editing">
      <Header />
      <div className="flexContainers">
        {/* <AdvisorSidebar /> */}
        <AdvisorSidebarLatest />
        <div className="profile-rightsidedatashow">
          <div className="tabs">
            <div
              className="name"
              // onClick={() => settabName("User Profile")}
              style={{
                color: tabName == "User Profile" && "var(--main-color)",
              }}
            >
              User Profile
              {tabName == "User Profile" && <div className="line"></div>}
            </div>
            <div
              className="name"
              style={{
                color: tabName == "Advisor Profile" && "var(--main-color)",
              }}
              //   onClick={() => settabName("Advisor Profile")}
            >
              Advisor Profile
              {tabName == "Advisor Profile" && <div className="line"></div>}
            </div>
            <div
              className="name"
              style={{
                color: tabName == "Products & Services" && "var(--main-color)",
              }}
              //  onClick={() => settabName("Products & Services")}
            >
              Products & Services
              {tabName == "Products & Services" && <div className="line"></div>}
            </div>

            <div
              className="name"
              style={{
                color: tabName == "Testimonials" && "var(--main-color)",
              }}
              //  onClick={() => settabName("Testimonials")}
            >
              Testimonials
              {tabName == "Testimonials" && <div className="line"></div>}
            </div>
          </div>
          {tabName == "User Profile" ? (
            <UssrProfile settabName={settabName} />
          ) : tabName == "Advisor Profile" ? (
            <AdvisorProfile settabName={settabName} />
          ) : tabName == "Products & Services" ? (
            <ProductsAndServices />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
