import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./firstsection.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../../../../assets/img1.svg";
import andriodlogo from "../../../../assets/andriodlogo.svg";
import apple from "../../../../assets/applelog.svg";
import a1 from "../../../../assets/a1.svg";
import a2 from "../../../../assets/a2.svg";
import a3 from "../../../../assets/a3.svg";
import a4 from "../../../../assets/a4.svg";
import a5 from "../../../../assets/a5.svg";
import a6 from "../../../../assets/a6.svg";
import { useHistory } from "react-router-dom";
import logolanding from "../../../../assets/landinglogo.svg";
import dashboardUI from "../../../../assets/Images/About/dashboard.svg";
import poweredBy from "../../../../assets/Images/About/mediverse.svg";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { allPoweredApps } from "../../../../assets/data";
import Pricing from "../../../Pricing/Pricing";
import Footer from "../../Footer/Footer";
import DashboardEmblaCarousel from "./DashboardCarousal";

export default function Planb() {
  const history = useHistory();
  const [anser, setanser] = useState("0");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownPowered, setDropdownPowered] = useState(false);
  const dropdownRef = useRef(null);
  const [poweredDropdownSelected, setPoweredDropdownSelected] = useState("");

  const pricingRef = useRef(null);
  const aboutRef = useRef(null);
  const productsRef = useRef(null);
  const sectionRefs = [pricingRef, aboutRef, productsRef];

  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    sectionRefs.forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => {
      sectionRefs.forEach((ref) => {
        if (ref.current) observer.unobserve(ref.current);
      });
    };
  }, [sectionRefs]);

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  //const
  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    // dots: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // centerMode: true,
    // centerPadding: "0", // Ensures slides are flush with container edges
    beforeChange: (current, next) => setActiveSlide(next),
  };

  const handleSlideClick = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  //functions

  const handleDropdownToggle = () => {
    console.log("dropdownOpen", dropdownOpen);
    setDropdownOpen(!dropdownOpen);
  };

  const handleNavigation = (route) => {
    history.push(route);
    setDropdownOpen(false);
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      console.log("Clicked outside");

      setDropdownOpen(false);
    }
  };

  //renderings

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const OPTIONS = { loop: true };
  const SLIDE_COUNT = 5;
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

  return (
    <>
      <div className="powered-nav">
        <div className="mainDiv">
          <div className="dropdownPowered">
            Powered By:{" "}
            <span onClick={() => setDropdownPowered((prev) => !prev)}>
              <img
                src={
                  poweredDropdownSelected?.image
                    ? poweredDropdownSelected?.image
                    : poweredBy
                }
                alt="poweredBy"
              />{" "}
              <span>
                {poweredDropdownSelected?.name
                  ? poweredDropdownSelected?.name
                  : "Mediverse"}
              </span>
              {dropdownPowered ? <FaCaretUp /> : <FaCaretDown />}
            </span>
          </div>
          <div className="flexDiv">
            <div>Contact Us</div>
            <div>Login</div>
            <div>Support</div>
          </div>
        </div>
        {dropdownPowered && (
          <div className="dropdownContainer">
            <div>
              <div className="allPoweredApps">
                {allPoweredApps?.map((eachApp, index) => {
                  return (
                    <div
                      className={
                        poweredDropdownSelected?.name === eachApp?.name &&
                        "selectedApp"
                      }
                      key={eachApp?.name + index}
                      onClick={() => {
                        setDropdownPowered(false);
                        setPoweredDropdownSelected(eachApp);
                      }}
                    >
                      <img src={eachApp?.image} alt={eachApp?.name} />
                      <span>{eachApp?.name}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="lanbding-nav">
        <div className="nav-tabs-text">
          <img src={logolanding} />
          <div className="text-nav">
            <div
              className={activeSection === "aboutSection" ? "selectedNav" : ""}
              onClick={() => scrollToSection(aboutRef)}
            >
              About
            </div>
            <div
              className={
                activeSection === "productsSection" ? "selectedNav" : ""
              }
              onClick={() => scrollToSection(productsRef)}
            >
              Products
            </div>
            <div
              className={
                activeSection === "pricingSection" ? "selectedNav" : ""
              }
              onClick={() => scrollToSection(pricingRef)}
            >
              Pricing
            </div>
            <div>Media</div>
          </div>

          {/* <label
            onClick={() =>histroy.push('/login')}
          >Login

            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
              <path d="M9.75649 0.983643L5.72715 5.01298L1.69782 0.983643L0.454285 2.22713L5.72715 7.5L11 2.22713L9.75649 0.983643Z" fill="white" />
            </svg>
          </label> */}
          <div className="dropdown-container" ref={dropdownRef}>
            <label onClick={handleDropdownToggle}>
              Demo
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="8"
                viewBox="0 0 11 8"
                fill="none"
                style={{ transform: dropdownOpen ? "rotate(180deg)" : "" }}
              >
                <path
                  d="M9.75649 0.983643L5.72715 5.01298L1.69782 0.983643L0.454285 2.22713L5.72715 7.5L11 2.22713L9.75649 0.983643Z"
                  fill="white"
                />
              </svg>
            </label>
            {dropdownOpen && (
              <div className="dropdown-menu">
                <div onClick={() => handleNavigation("/client/login")}>
                  Client
                </div>
                <div onClick={() => handleNavigation("/advisor/login")}>
                  Advisor
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="planb-dashboard">
        <div className="pageOne">
          <div className="leftsidetext">
            <span>Modernize Your </span>
            <span>Brokerage, PEO or TPA</span>
            <h2>With Your Own White-labeled App</h2>
            <p>
              CoverFi transforms your client, agent, and vendor experience with
              a complete suite of branded applications.
            </p>

            {/* <div className="tableinside">
              <div className="cointingtable">
                <div className="value ">30K</div>
                <div className="title">Clients & Projects</div>
              </div>
              <div className="cointingtable">
                <div className="value ">1600+</div>
                <div className="title">Advisors</div>
              </div>
              <div className="cointingtable">
                <div className="value ">90%</div>
                <div className="title">Clients returned</div>
              </div>
            </div> */}
          </div>
          <div className="rightside-chartsection">
            {/* <div className="rightsidechatside">
              <div className="bottom-message">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1661_468)">
                    <path
                      d="M27.8292 19.7992L23.1771 27.3374L21.2598 26.1521L25.912 18.6139L27.8292 19.7992ZM9.55888 2.31831L8.37807 0.394287L0.782333 5.04308L1.95863 6.96598L9.55888 2.31831ZM23.5481 6.22727L25.6909 4.08445L24.0985 2.48748L21.9658 4.62016C20.4259 3.39903 18.543 2.68954 16.5802 2.59086C14.6174 2.49219 12.6728 3.00925 11.0182 4.06979L3.39206 8.29791C2.98622 8.56637 2.64515 8.92171 2.39355 9.3382C2.14195 9.7547 1.98611 10.2219 1.93731 10.7061C1.88852 11.1902 1.948 11.6792 2.11144 12.1375C2.27487 12.5958 2.53818 13.0121 2.8823 13.3561L14.8189 25.2972C15.1217 25.6032 15.4821 25.8462 15.8793 26.0121C16.2766 26.178 16.7027 26.2635 17.1332 26.2638C17.2745 26.2635 17.4158 26.2549 17.5561 26.2378C18.0513 26.1775 18.5258 26.0038 18.943 25.7304C19.3602 25.457 19.7088 25.0911 19.9617 24.6612L24.3917 16.7824C25.3115 15.137 25.7223 13.2557 25.5721 11.3768C25.422 9.49778 24.7176 7.70563 23.5481 6.22727ZM4.1725 20.9439C4.16588 21.868 4.39965 22.7779 4.85088 23.5843C5.3021 24.3907 5.95526 25.0659 6.74625 25.5436C7.53723 26.0213 8.43885 26.2852 9.3626 26.3092C10.2863 26.3332 11.2005 26.1166 12.0152 25.6807L4.80407 18.4628C4.39449 19.226 4.17767 20.0778 4.1725 20.9439Z"
                      fill="var(--main-color)"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1661_468">
                      <rect
                        width="27.0672"
                        height="27.0672"
                        fill="white"
                        transform="translate(0.789185 0.324341)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div className="text-bottom-message">
                  <div className="text">12 messages from advisor</div>
                  <div className="date">Today, 1:25 PM</div>
                </div>
              </div>
              <div class="donutchart">
                <div class="textcenter">
                  <div class="goalreach">Goals Reached</div>
                  <div class="goaltext">{anser}</div>
                </div>
                <figure>
                  <div class="figure-content">
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 42 42"
                      class="donut"
                      aria-labelledby="beers-title beers-desc"
                      role="img"
                    >
                      <title id="beers-title">Beers in My Cellar</title>
                      <desc id="beers-desc">
                        Donut chart showing 10 total beers. Two beers are
                        Imperial India Pale Ales, four beers are Belgian
                        Quadrupels, and three are Russian Imperial Stouts. The
                        last remaining beer is unlabeled.
                      </desc>
                      <circle
                        class="donut-hole"
                        cx="21"
                        cy="21"
                        r="15.91549430918954"
                        fill="#fff"
                        role="presentation"
                      ></circle>
                      <circle
                        class="donut-ring"
                        cx="21"
                        cy="21"
                        r="15.91549430918954"
                        fill="transparent"
                        stroke="#d2d3d4"
                        stroke-width="5"
                        role="presentation"
                      ></circle>

                      <circle
                        class="donut-segment"
                        cx="21"
                        cy="21"
                        r="15.91549430918954"
                        fill="transparent"
                        stroke="var(--main-color)"
                        stroke-width="5"
                        stroke-dasharray="40 50"
                        stroke-dashoffset="25"
                        aria-labelledby="donut-segment-1-title donut-segment-1-desc"
                      >
                        <title id="donut-segment-1-title">
                          Belgian Quadrupels
                        </title>
                        <desc id="donut-segment-1-desc">
                          Pink chart segment spanning 40% of the whole, which is
                          4 Belgian Quadrupels out of 10 total.
                        </desc>
                      </circle>
                    </svg>
                  </div>
                </figure>
              </div>
              <div className="text-top">
                <div className="incometext">Total Income</div>
                <div className="labeldropdown">
                  Week{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                  >
                    <path
                      d="M9.62648 0.977051L5.59715 5.00639L1.56781 0.977051L0.32428 2.22054L5.59715 7.49341L10.87 2.22054L9.62648 0.977051Z"
                      fill="#8F8F8F"
                    />
                  </svg>
                </div>
              </div>
              <div id="chart">
                <ReactApexChart
                  options={chartdat.options}
                  series={chartdat.series}
                  type="line"
                  height={150}
                />
              </div>
              <div id="html-dist"></div>
            </div> */}
            <img src={dashboardUI} alt="dashboardUI" />
          </div>
        </div>
        <div className="borderlinemiddle">
          <div className="content">
            <div className="title">Explore Solutions For:</div>
            <div className="specialities">
              <div>Brokerages</div>
              <div>PEO's</div>
              <div>TPA’s</div>
              <div>Carriers</div>
              <div>Providers</div>
              <div>Groups</div>
              <div>
                Company HR
                <span>New</span>
              </div>
            </div>
          </div>
        </div>

        {/* <div >
          <div className="slider-container" ref={aboutRef} id="aboutSection">
            <Slider {...settings} ref={sliderRef}>
              <div
                className={
                  activeSlide === 0 ? "active-slide" : "inactive-slide"
                }
                onClick={() => handleSlideClick(0)}
              >
                <img src={fullDashboard0} alt="fullDashboard0" />
              </div>
              <div
                className={
                  activeSlide === 1 ? "active-slide" : "inactive-slide"
                }
                onClick={() => handleSlideClick(1)}
              >
                <img src={fullDashboard} alt="fullDashboard" />
              </div>
              <div
                className={
                  activeSlide === 2 ? "active-slide" : "inactive-slide"
                }
                onClick={() => handleSlideClick(2)}
              >
                <img src={fullDashboard1} alt="fullDashboard1" />
              </div>
              <div
                className={
                  activeSlide === 4 ? "active-slide" : "inactive-slide"
                }
                onClick={() => handleSlideClick(3)}
              >
                <img src={fullDashboard0} alt="fullDashboard0" />
              </div>
              <div
                className={
                  activeSlide === 5 ? "active-slide" : "inactive-slide"
                }
                onClick={() => handleSlideClick(4)}
              >
                <img src={fullDashboard} alt="fullDashboard" />
              </div>
              <div
                className={
                  activeSlide === 6 ? "active-slide" : "inactive-slide"
                }
                onClick={() => handleSlideClick(5)}
              >
                <img src={fullDashboard1} alt="fullDashboard1" />
              </div>
            </Slider>
          </div>
        </div> */}
        <div className="sliderContainer" ref={aboutRef} id="aboutSection">
          <DashboardEmblaCarousel slides={SLIDES} options={OPTIONS} />
        </div>

        <div className="pagwtwo">
          <div className="aboutCards">
            <div className="cardss centeredCards">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_104_1379)">
                  <path
                    d="M17.4999 18.2298C17.4999 16.9148 16.9775 15.6536 16.0476 14.7237C15.1178 13.7939 13.8566 13.2715 12.5416 13.2715C11.2265 13.2715 9.96536 13.7939 9.03549 14.7237C8.10562 15.6536 7.58323 16.9148 7.58323 18.2298V18.6673H11.0832V18.2298C11.0832 17.843 11.2369 17.4721 11.5104 17.1986C11.7839 16.9251 12.1548 16.7715 12.5416 16.7715C12.9283 16.7715 13.2993 16.9251 13.5728 17.1986C13.8463 17.4721 13.9999 17.843 13.9999 18.2298C13.9999 18.959 12.6092 20.002 11.4916 20.8397C9.6599 22.214 7.58323 23.7715 7.58323 26.2507C7.58323 26.7148 7.7676 27.1599 8.09579 27.4881C8.42398 27.8163 8.8691 28.0007 9.33323 28.0007H17.4999V24.5007H12.4832C12.8449 24.2043 13.2357 23.9173 13.5974 23.6408C15.4232 22.2665 17.4999 20.709 17.4999 18.2298Z"
                    fill="#025F4C"
                  />
                  <path
                    d="M24.4998 14V19.8333H23.6248C23.2382 19.8327 22.8677 19.6789 22.5943 19.4055C22.321 19.1322 22.1671 18.7616 22.1665 18.375V14H18.6665V18.375C18.668 19.6896 19.1909 20.9498 20.1205 21.8794C21.05 22.8089 22.3103 23.3318 23.6248 23.3333H24.4998V28H27.9998V14H24.4998Z"
                    fill="#025F4C"
                  />
                  <path
                    d="M23.883 4.11792C21.4589 1.67972 18.2211 0.220711 14.7889 0.019953C11.3566 -0.180805 7.97078 0.890781 5.27902 3.02975C2.58726 5.16872 0.778496 8.22493 0.198691 11.6138C-0.381114 15.0027 0.308738 18.4864 2.1363 21.3986L4.69363 18.8401C3.85728 17.2412 3.44789 15.4534 3.50509 13.6498C3.56228 11.8463 4.08413 10.0881 5.02011 8.54533C5.95609 7.00261 7.27451 5.72766 8.84773 4.84389C10.4209 3.96013 12.1957 3.49748 14.0001 3.50075C16.7817 3.51046 19.4464 4.62071 21.412 6.58892L18.6668 9.33409H25.5606C25.7278 9.33424 25.8934 9.30142 26.0479 9.23751C26.2024 9.1736 26.3428 9.07985 26.461 8.96162C26.5792 8.8434 26.673 8.70302 26.7369 8.54853C26.8008 8.39403 26.8336 8.22845 26.8335 8.06125V1.16742L23.883 4.11792Z"
                    fill="#025F4C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_104_1379">
                    <rect width="28" height="28" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <div className="title-text">Goal Setting</div>
              <div className="des">
                Define your dream vacation, child's education, or retirement
                home. We'll help you chart the course.
              </div>
            </div>

            <div className="cardss centeredCards">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_104_1386)">
                  <path
                    d="M3.5 23.9167V0H0V23.9167C0 24.9996 0.430207 26.0382 1.19598 26.804C1.96175 27.5698 3.00037 28 4.08333 28H28V24.5H4.08333C3.92862 24.5 3.78025 24.4385 3.67085 24.3291C3.56146 24.2197 3.5 24.0714 3.5 23.9167Z"
                    fill="#025F4C"
                  />
                  <path
                    d="M26.2501 5.8335H19.8334L22.6789 8.679L18.6668 12.6923L15.7501 9.77566L7.51227 18.0123L9.98793 20.488L15.7501 14.7247L18.6668 17.6413L25.1546 11.1547L28.0001 14.0002V7.5835C28.0001 7.11937 27.8157 6.67425 27.4875 6.34606C27.1593 6.01787 26.7142 5.8335 26.2501 5.8335Z"
                    fill="#025F4C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_104_1386">
                    <rect width="28" height="28" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <div className="title-text">Advisor Interaction</div>
              <div className="des">
                Connect with qualified advisors who understand your needs and
                craft personalized plans.
              </div>
            </div>

            <div className="cardss centeredCards">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_104_1392)">
                  <path
                    d="M28 22.1667V4.08333C28 3.00037 27.5698 1.96175 26.804 1.19598C26.0382 0.430207 24.9996 0 23.9167 0H4.08333C3.00037 0 1.96175 0.430207 1.19598 1.19598C0.430207 1.96175 0 3.00037 0 4.08333L0 22.1667H12.25V24.5H7V28H21V24.5H15.75V22.1667H28ZM3.5 4.08333C3.5 3.92862 3.56146 3.78025 3.67085 3.67085C3.78025 3.56146 3.92862 3.5 4.08333 3.5H23.9167C24.0714 3.5 24.2197 3.56146 24.3291 3.67085C24.4385 3.78025 24.5 3.92862 24.5 4.08333V18.6667H3.5V4.08333ZM17.15 9.33333H22.1667V12.8333H19.0167L15.5295 18.0635L12.0295 11.0635L10.85 12.8333H5.83333V9.33333H8.98333L12.4705 4.10317L15.9705 11.1032L17.15 9.33333Z"
                    fill="#025F4C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_104_1392">
                    <rect width="28" height="28" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <div className="title-text">Proposal Creation</div>
              <div className="des">
                Review tailored investment proposals designed to achieve your
                goals.
              </div>
            </div>
          </div>
        </div>

        <div
          className="pagwtwo pagetwobox"
          ref={productsRef}
          id="productsSection"
        >
          <div className="cardss">
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.6667 7.66667V6.70833C30.6667 4.92917 29.9599 3.22288 28.7018 1.96483C27.4438 0.706769 25.7375 0 23.9583 0H0V46H5.75V26.8333H19.1667V27.7917C19.1667 29.5708 19.8734 31.2771 21.1315 32.5352C22.3895 33.7932 24.0958 34.5 25.875 34.5H46V7.66667H30.6667ZM5.75 5.75H23.9583C24.2125 5.75 24.4563 5.85097 24.636 6.03069C24.8157 6.21041 24.9167 6.45417 24.9167 6.70833V21.0833H5.75V5.75ZM40.25 28.75H25.875C25.6208 28.75 25.3771 28.649 25.1974 28.4693C25.0176 28.2896 24.9167 28.0458 24.9167 27.7917V26.8333H30.6667V13.4167H40.25V28.75Z"
                fill="#025F4C"
              />
            </svg>
            <div className="title-text">Goal Setting</div>
            <div className="des">
              Define your dream vacation, child's education, or retirement home.
              We'll help you chart the course.
            </div>
          </div>

          <div className="cardss">
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.6667 7.66667V6.70833C30.6667 4.92917 29.9599 3.22288 28.7018 1.96483C27.4438 0.706769 25.7375 0 23.9583 0H0V46H5.75V26.8333H19.1667V27.7917C19.1667 29.5708 19.8734 31.2771 21.1315 32.5352C22.3895 33.7932 24.0958 34.5 25.875 34.5H46V7.66667H30.6667ZM5.75 5.75H23.9583C24.2125 5.75 24.4563 5.85097 24.636 6.03069C24.8157 6.21041 24.9167 6.45417 24.9167 6.70833V21.0833H5.75V5.75ZM40.25 28.75H25.875C25.6208 28.75 25.3771 28.649 25.1974 28.4693C25.0176 28.2896 24.9167 28.0458 24.9167 27.7917V26.8333H30.6667V13.4167H40.25V28.75Z"
                fill="white"
              />
            </svg>
            <div className="title-text">Advisor Interaction</div>
            <div className="des">
              Connect with qualified advisors who understand your needs and
              craft personalized plans.
            </div>
          </div>

          <div className="cardss">
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.6667 7.66667V6.70833C30.6667 4.92917 29.9599 3.22288 28.7018 1.96483C27.4438 0.706769 25.7375 0 23.9583 0H0V46H5.75V26.8333H19.1667V27.7917C19.1667 29.5708 19.8734 31.2771 21.1315 32.5352C22.3895 33.7932 24.0958 34.5 25.875 34.5H46V7.66667H30.6667ZM5.75 5.75H23.9583C24.2125 5.75 24.4563 5.85097 24.636 6.03069C24.8157 6.21041 24.9167 6.45417 24.9167 6.70833V21.0833H5.75V5.75ZM40.25 28.75H25.875C25.6208 28.75 25.3771 28.649 25.1974 28.4693C25.0176 28.2896 24.9167 28.0458 24.9167 27.7917V26.8333H30.6667V13.4167H40.25V28.75Z"
                fill="#025F4C"
              />
            </svg>
            <div className="title-text">Proposal Creation</div>
            <div className="des">
              Review tailored investment proposals designed to achieve your
              goals.
            </div>
          </div>

          <div className="cardss">
            <svg
              width="47"
              height="47"
              viewBox="0 0 47 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M31.0002 8.40623V7.4479C31.0002 5.66874 30.2934 3.96244 29.0353 2.70439C27.7773 1.44633 26.071 0.739563 24.2918 0.739563H0.333496V46.7396H6.0835V27.5729H19.5002V28.5312C19.5002 30.3104 20.2069 32.0167 21.465 33.2747C22.723 34.5328 24.4293 35.2396 26.2085 35.2396H46.3335V8.40623H31.0002ZM6.0835 6.48956H24.2918C24.546 6.48956 24.7898 6.59053 24.9695 6.77025C25.1492 6.94997 25.2502 7.19373 25.2502 7.4479V21.8229H6.0835V6.48956ZM40.5835 29.4896H26.2085C25.9543 29.4896 25.7106 29.3886 25.5309 29.2089C25.3511 29.0292 25.2502 28.7854 25.2502 28.5312V27.5729H31.0002V14.1562H40.5835V29.4896Z"
                fill="white"
              />
            </svg>
            <div className="title-text">Real-Time Updates</div>
            <div className="des">
              Stay informed on proposal status and track your progress towards
              financial milestones.
            </div>
          </div>

          <div className="cardss">
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.6667 7.66667V6.70833C30.6667 4.92917 29.9599 3.22288 28.7018 1.96483C27.4438 0.706769 25.7375 0 23.9583 0H0V46H5.75V26.8333H19.1667V27.7917C19.1667 29.5708 19.8734 31.2771 21.1315 32.5352C22.3895 33.7932 24.0958 34.5 25.875 34.5H46V7.66667H30.6667ZM5.75 5.75H23.9583C24.2125 5.75 24.4563 5.85097 24.636 6.03069C24.8157 6.21041 24.9167 6.45417 24.9167 6.70833V21.0833H5.75V5.75ZM40.25 28.75H25.875C25.6208 28.75 25.3771 28.649 25.1974 28.4693C25.0176 28.2896 24.9167 28.0458 24.9167 27.7917V26.8333H30.6667V13.4167H40.25V28.75Z"
                fill="#025F4C"
              />
            </svg>
            <div className="title-text">Investment Preferences</div>
            <div className="des">
              Choose investment options that align with your risk tolerance and
              goals.
            </div>
          </div>

          <div className="cardss">
            <svg
              width="47"
              height="47"
              viewBox="0 0 47 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M31.0002 8.40623V7.4479C31.0002 5.66874 30.2934 3.96244 29.0353 2.70439C27.7773 1.44633 26.071 0.739563 24.2918 0.739563H0.333496V46.7396H6.0835V27.5729H19.5002V28.5312C19.5002 30.3104 20.2069 32.0167 21.465 33.2747C22.723 34.5328 24.4293 35.2396 26.2085 35.2396H46.3335V8.40623H31.0002ZM6.0835 6.48956H24.2918C24.546 6.48956 24.7898 6.59053 24.9695 6.77025C25.1492 6.94997 25.2502 7.19373 25.2502 7.4479V21.8229H6.0835V6.48956ZM40.5835 29.4896H26.2085C25.9543 29.4896 25.7106 29.3886 25.5309 29.2089C25.3511 29.0292 25.2502 28.7854 25.2502 28.5312V27.5729H31.0002V14.1562H40.5835V29.4896Z"
                fill="white"
              />
            </svg>
            <div className="title-text">Collaboration Tools</div>
            <div className="des">
              Communicate seamlessly with your advisor through direct messaging
              and proposal editing.
            </div>
          </div>
        </div>

        <Pricing refValue={pricingRef} idd="pricingSection" />

        {/* <div className="pagethree">
          <h1>Discover Our Mission & Vision </h1>
          <div className="threebox">
            <div
              style={{
                width: "50%",
                paddingRight: "15px",
              }}
            >
              <h1>to Your Financial Success</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam
              </p>
              <label>Know more</label>
            </div>
            <div
              className="lol"
              style={{
                width: "25%",
              }}
            >
              <div className="whatdo">What we do</div>
              <div className="whatdotext">
                We empower individuals to achieve their financial dreams through
                personalized goal-based planning and expert advisor connections.
              </div>
            </div>
            <div
              className="lol"
              style={{
                width: "25%",
                background: "var(--main-color-light)",
              }}
            >
              <div
                className="whatdo"
                style={{
                  color: "#02364A",
                }}
              >
                Origin idea
              </div>
              <div
                className="whatdotext"
                style={{
                  color: "#02364A",
                }}
              >
                CoverFi was born from the desire to make financial planning
                accessible and collaborative
              </div>
            </div>
          </div>

          <div className="threebox">
            <div
              className="lol"
              style={{
                background: "white",
                width: "25%",
              }}
            ></div>

            <div
              className="lol"
              style={{
                width: "25%",
              }}
            >
              <div className="whatdo">Mission</div>
              <div className="whatdotext">
                To bridge the gap between individuals and financial security.
              </div>
            </div>

            <div
              className="lol"
              style={{
                width: "25%",
                background: "var(--main-color-light)",
              }}
            >
              <div
                className="whatdo"
                style={{
                  color: "#02364A",
                }}
              >
                Vision
              </div>
              <div
                className="whatdotext"
                style={{
                  color: "#02364A",
                }}
              >
                To be the leading platform for goal-based financial planning in
                India.
              </div>
            </div>
            <div
              className="lol"
              style={{
                width: "25%",
              }}
            >
              <div className="whatdo">Objective</div>
              <div className="whatdotext">
                To democratize financial planning by making it accessible,
                affordable, and personalized for everyone.
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="page-four">
          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          <div className="clientName">Client Testimonials</div>

          <div className="containers">
            <link
              rel="stylesheet"
              type="text/css"
              charset="UTF-8"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />
            <style>{cssstyle}</style>

            <Slider {...settings}>
              <div className="cardimg">
                <img src={img1} />
                <div className="titel">Saurav.R</div>

                <div className="des">
                  CoverFi helped me clarify my financial goals and connect with
                  an advisor who created a plan I can trust
                </div>
              </div>

              <div className="cardimg">
                <img src={img1} />
                <div className="titel">Priya.D</div>

                <div className="des">
                  CoverFi helped me clarify my financial goals and connect with
                  an advisor who created a plan I can trust
                </div>
              </div>

              <div className="cardimg">
                <img src={img1} />
                <div className="titel">Amit.K</div>

                <div className="des">
                  CoverFi helped me clarify my financial goals and connect with
                  an advisor who created a plan I can trust
                </div>
              </div>

              <div className="cardimg">
                <img src={img1} />
                <div className="titel">Sau.p</div>

                <div className="des">
                  CoverFi helped me clarify my financial goals and connect with
                  an advisor who created a plan I can trust
                </div>
              </div>
              <div className="cardimg">
                <img src={img1} />
                <div className="titel">ram.R</div>

                <div className="des">
                  CoverFi helped me clarify my financial goals and connect with
                  an advisor who created a plan I can trust
                </div>
              </div>
            </Slider>
          </div>
          
        </div> */}

        <div className="sec-five">
          <div className="title">Frequently Asked Questions</div>
          <div className="box-section">
            <div className="box">
              What types of financial goals can I set with CoverFi?
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M17.25 8.25001H9.74999V0.749988C9.74999 0.335777 9.41421 0 9 0C8.58579 0 8.25001 0.335777 8.25001 0.749988V8.24998H0.749988C0.335777 8.25001 0 8.58579 0 9C0 9.41421 0.335777 9.74999 0.749988 9.74999H8.24998V17.25C8.24998 17.6642 8.58575 18 8.99996 18C9.41417 18 9.74995 17.6642 9.74995 17.25V9.74999H17.2499C17.6642 9.74999 17.9999 9.41421 17.9999 9C18 8.58579 17.6642 8.25001 17.25 8.25001Z"
                  fill="#02364A"
                />
              </svg>
            </div>
            <div className="box">
              How do I connect with an investment advisor?
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M17.25 8.25001H9.74999V0.749988C9.74999 0.335777 9.41421 0 9 0C8.58579 0 8.25001 0.335777 8.25001 0.749988V8.24998H0.749988C0.335777 8.25001 0 8.58579 0 9C0 9.41421 0.335777 9.74999 0.749988 9.74999H8.24998V17.25C8.24998 17.6642 8.58575 18 8.99996 18C9.41417 18 9.74995 17.6642 9.74995 17.25V9.74999H17.2499C17.6642 9.74999 17.9999 9.41421 17.9999 9C18 8.58579 17.6642 8.25001 17.25 8.25001Z"
                  fill="#02364A"
                />
              </svg>
            </div>
            <div className="box">
              Is CoverFi App Phase 1 available on mobile devices?
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M17.25 8.25001H9.74999V0.749988C9.74999 0.335777 9.41421 0 9 0C8.58579 0 8.25001 0.335777 8.25001 0.749988V8.24998H0.749988C0.335777 8.25001 0 8.58579 0 9C0 9.41421 0.335777 9.74999 0.749988 9.74999H8.24998V17.25C8.24998 17.6642 8.58575 18 8.99996 18C9.41417 18 9.74995 17.6642 9.74995 17.25V9.74999H17.2499C17.6642 9.74999 17.9999 9.41421 17.9999 9C18 8.58579 17.6642 8.25001 17.25 8.25001Z"
                  fill="#02364A"
                />
              </svg>
            </div>
            <div className="box">
              How secure is my financial information on CoverFi?
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M17.25 8.25001H9.74999V0.749988C9.74999 0.335777 9.41421 0 9 0C8.58579 0 8.25001 0.335777 8.25001 0.749988V8.24998H0.749988C0.335777 8.25001 0 8.58579 0 9C0 9.41421 0.335777 9.74999 0.749988 9.74999H8.24998V17.25C8.24998 17.6642 8.58575 18 8.99996 18C9.41417 18 9.74995 17.6642 9.74995 17.25V9.74999H17.2499C17.6642 9.74999 17.9999 9.41421 17.9999 9C18 8.58579 17.6642 8.25001 17.25 8.25001Z"
                  fill="#02364A"
                />
              </svg>
            </div>
            <div className="box">
              How secure is my financial information on CoverFi?
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M17.25 8.25001H9.74999V0.749988C9.74999 0.335777 9.41421 0 9 0C8.58579 0 8.25001 0.335777 8.25001 0.749988V8.24998H0.749988C0.335777 8.25001 0 8.58579 0 9C0 9.41421 0.335777 9.74999 0.749988 9.74999H8.24998V17.25C8.24998 17.6642 8.58575 18 8.99996 18C9.41417 18 9.74995 17.6642 9.74995 17.25V9.74999H17.2499C17.6642 9.74999 17.9999 9.41421 17.9999 9C18 8.58579 17.6642 8.25001 17.25 8.25001Z"
                  fill="#02364A"
                />
              </svg>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

const cssstyle = `
.containers {

}
h3 {
    background: #5f9ea0;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
}
.slick-next:before, .slick-prev:before {
    color: #000;
}
.center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    -ms-transform: scale(1.08);
    transform: scale(1.08);
}
.center h3 {
    transition: all .10s ease;
}
`;
